const resourceQuery = {
  country: {
    apiUrl: 'v1/public/country/list',
    queryKey: ['public', 'country'],
    customParams: {
      perPage: 300,
      sort: 'index@asc',
    },
    staleTime: Infinity,
  },
  prefecture: {
    apiUrl: 'v1/admin/prefecture/list',
    queryKey: ['admin', 'prefecture'],
    customParams: {
      perPage: 100,
    },
    staleTime: Infinity,
  },
  city: {
    apiUrl: 'v1/public/city/list',
    queryKey: ['public', 'city'],
    customParams: {
      perPage: 500,
    },
    staleTime: Infinity,
  },
  line: {
    apiUrl: 'v1/public/station/lines',
    queryKey: ['public', 'line'],
    customParams: {
      perPage: 999,
    },
    staleTime: Infinity,
  },
  station: {
    customParams: {
      perPage: 100,
    },
    staleTime: Infinity,
  },
  role: {
    apiUrl: '/v1/experience/role/category/list',
    queryKey: ['public', 'role'],
    customParams: {
      perPage: 100,
    },
    staleTime: Infinity,
  },
  process: {
    apiUrl: '/v1/experience/role/process/list',
    queryKey: ['public', 'process'],
    customParams: {
      perPage: 100,
    },
    staleTime: Infinity,
  },
  level: {
    apiUrl: '/v1/level/list',
    queryKey: ['public', 'level'],
    customParams: {
      perPage: 100,
    },
    staleTime: Infinity,
  },
  license: {
    apiUrl: '/v1/experience/certifications/item/list',
    queryKey: ['certification', 'role'],
    staleTime: Infinity,
    customParams: {
      perPage: 150,
      group: true,
      type: 'role',
      sort: 'index@asc',
    },
  },
  industry: {
    apiUrl: '/v1/experience/domain-knowledge/industries',
    queryKey: ['public', 'industries'],
    customParams: {
      perPage: 100,
      sort: 'sort@asc',
    },
    staleTime: Infinity,
  },
  languageTypes: {
    apiUrl: '/v1/experience/language/types',
    queryKey: ['public', 'languageTypes'],
    customParams: {
      perPage: 100,
    },
    staleTime: Infinity,
  },
  technicalCategory: {
    apiUrl: '/v1/experience/technical/category',
    queryKey: ['public', 'technicalCategory'],
    customParams: {
      perPage: 100,
    },
    staleTime: Infinity,
  },
  technicalSkill: {
    apiUrl: ({ categoryTechnical }: { categoryTechnical: string }) =>
      `/v1/experience/technical/skill/${categoryTechnical}`,
    queryKey: ['public', 'technicalSkill'],
    customParams: {
      perPage: 150,
    },
    staleTime: Infinity,
  },
  inChargeProcess: {
    apiUrl: '/v1/experience/work/inChargeProcess',
    queryKey: ['public', 'inChargeProcess'],
    customParams: {
      perPage: 100,
      sort: 'index@asc',
    },
    staleTime: Infinity,
  },
  roleWork: {
    apiUrl: '/v1/experience/work/role',
    queryKey: ['public', 'roleWork'],
    customParams: {
      perPage: 100,
    },
    staleTime: Infinity,
  },
  settingValue: {
    apiUrl: '/v1/setting/get/name',
    queryKey: ['setting', 'settingValue'],
    staleTime: Infinity,
  },
};
export default resourceQuery;
