import HeaderComponent from 'components/Header';
import GreenBox from 'components/GreenBox';
import './styles.less';
import errorImg from 'assets/images/expiredIcon.png';

const LinkingConfirmErrorPage = (): JSX.Element => {
  return (
    <div>
      <HeaderComponent />
      <div className="error-wrapper">
        <GreenBox title="">
          <img src={errorImg} alt="error-img" className="expired-img" />
          <div className="expired-title">対象リンクの有効期限が切れています。</div>
          <div className="expired-content">
            Aperportの管理者に連携許可依頼のメールの再送信をお頼みください。
          </div>
        </GreenBox>
      </div>
    </div>
  );
};

export default LinkingConfirmErrorPage;
