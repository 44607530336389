import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
// import _merge from 'lodash/merge';
import lang from 'providers/LangProvider/slice';
import auth from 'providers/AuthProvider/slice';
import general from 'providers/GeneralProvider/slice';
import Plugins from 'plugins';
// import Extensions from 'extensions';
// PLOP: Import reducers below -- DO NOT ERASE
import notification from 'providers/NotificationProvider/slice';

const createRootReducer = (history: History): Reducer => {
  const originalReducer = {
    router: connectRouter(history),
    lang,
    auth,
    general,
    // PLOP: Use reducers below -- DO NOT ERASE
    notification,
  };
  // const allReducer = _merge(Plugins.slice, Extensions.slice);
  const allReducer = Plugins.slice;
  const reducer = { ...allReducer, ...originalReducer };
  return combineReducers(reducer);
};
export default createRootReducer;
