import { object, string, ref } from 'yup';
import { Regex } from 'utils/constants';

const ChangeEmailSchema = object({
  email: string()
    .trim()
    .required('メールアドレスを入力してください。')
    .matches(Regex.EMAIL, '不正なメールアドレスの形式です。'),
  confirmEmail: string()
    .trim()
    .required('メールアドレスを入力してください。')
    .matches(Regex.EMAIL, '不正なメールアドレスの形式です。')
    .oneOf([ref('email'), null], 'メールアドレスが一致する必要があります'),
});

export default ChangeEmailSchema;
