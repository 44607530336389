import { object, string } from 'yup';

const FormSchema = object({
  usedTechnologyCategory: string(),
  usedTechnologyName: string(),
  otherUsedTechnologyName: string().trim(),
  usedTechnologyLevel: string(),
});

export default FormSchema;
