import React from 'react';
import RegisterForm from 'components/auth/Register';
import './styles.less';

const RegisterPage: React.FC = () => {
  return (
    <div className="register-page-wrapper">
      <RegisterForm />
    </div>
  );
};

export default RegisterPage;
