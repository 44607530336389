const settingQuery = {
  settingList: {
    apiUrl: '/v1/setting/list',
    queryKey: ['setting', 'list'],
    customParams: {
      perPage: 300,
    },
    staleTime: Infinity,
  },
  getHelpText: {
    apiUrl: '/v1/setting/get/name/helperText',
    method: 'get',
  },
  recommendationList: {
    apiUrl: '/v1/recommendation/list',
    queryKey: ['recommendation'],
    customParams: {
      perPage: 300,
    },
    staleTime: Infinity,
  },
};

export default settingQuery;
