import { Switch, Route } from 'react-router-dom';

import routes from './routes';

const SkillsPage = (): JSX.Element => {
  return (
    <Switch>
      {routes.map((route) => (
        <Route exact={route.exact} path={route.path} component={route.component} key={route.path} />
      ))}
    </Switch>
  );
};

export default SkillsPage;
