/* eslint-disable import/order */
// import moment from 'moment';
import moment from 'moment-timezone';
import { Space, Button, List, Typography, Col, Row, Tag } from 'antd';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import _get from 'lodash/get';
import { IListWorkExperience } from 'models/skills/interface';
import TextHasReadMore from '../TextHasReadMore';
import '../skills-style.less';
import people from 'assets/images/people_24px.svg';
import business from 'assets/images/business_center_24px.svg';
import { useTranslation } from 'react-i18next';
const { Text, Title } = Typography;

interface PropsHeader {
  data: Array<IListWorkExperience>;
  className?: string;
  initLoading?: boolean;
  handleEdit: (objectItem: IListWorkExperience) => void;
  handleDelete: (objectId: string | any) => void;
}

const DataList: React.FC<PropsHeader> = ({
  data,
  className,
  initLoading,
  handleEdit,
  handleDelete,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Title
        level={4}
        className="title"
        style={{
          marginTop: '80px',
        }}
      >
        {t('title:workExperienceTitle')}
      </Title>
      <List
        className={`skills-list-container skills-list work-experience ${className || ''}`}
        itemLayout="horizontal"
        dataSource={data?.slice(0, 20)}
        loading={initLoading}
        renderItem={(item: IListWorkExperience) => (
          <List.Item
            actions={[
              <Button
                className="btn-delete"
                shape="circle"
                icon={<CloseRoundedIcon />}
                onClick={() => handleDelete(_get(item, '_id'))}
              />,
              <Button
                className="btn-edit"
                shape="circle"
                icon={<CreateRoundedIcon />}
                onClick={() => handleEdit(item)}
              />,
            ]}
            key={item._id}
          >
            <Space className="content-item" direction="vertical">
              <Row justify="start">
                <Col span={13}>
                  <Text className="projectName">{_get(item, 'projectName')}</Text>
                  <br />
                  <Text className="periodStart">
                    {item.periodStart
                      ? moment(item.periodStart).tz('Asia/Tokyo').format('YYYY年MM月')
                      : ''}{' '}
                    —
                  </Text>
                  {item.periodEnd ? (
                    <Text className="periodEnd">{moment(item.periodEnd).format('YYYY年MM月')}</Text>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>

              <Row>
                {item.endUser && (
                  <Col span={12}>
                    <div className="ellipse ">
                      <img src={people} alt="" />
                    </div>
                    <Text className="sub-title">エンドユーザー</Text>
                    <br />
                    <Text className="sub-content">{item.endUser}</Text>
                  </Col>
                )}
                {item.responsibilityCompany && (
                  <Col span={12}>
                    <div className="ellipse ">
                      <img src={business} alt="" />
                    </div>
                    <Text className="sub-title">プロジェクト責任会社</Text>
                    <br />
                    <Text className="sub-content">{item.responsibilityCompany}</Text>
                  </Col>
                )}
              </Row>
              <div className="detail">
                <Row>
                  <Col span={8}>
                    <Text className="sub-title">役割</Text>
                  </Col>
                  <Col span={16}>
                    <Text className="roles">
                      {_get(item, 'roles', []).map((r, index) => {
                        if (index === 0) {
                          return r.name;
                        }
                        return '/' + r.name;
                      })}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Text className="sub-title">担当工程</Text>
                  </Col>
                  <Col span={16}>
                    <div className="inChargeProcesses">
                      {_get(item, 'inChargeProcesses', []).map((p) => {
                        return (
                          <Tag key={p._id} color="#7dad96">
                            {p.name}
                          </Tag>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
                {item.technologies &&
                  item.technologies.length > 0 &&
                  item.technologies.map((tech, index) => {
                    const totalTechnologies = item.technologies?.length ?? 0;
                    return (
                      <Row
                        key={index}
                        className={`item-technology ${
                          index === totalTechnologies - 1 ? 'end' : ''
                        }`}
                      >
                        <Col span={8}>
                          {index === 0 && <Text className="sub-title">使用技術</Text>}
                        </Col>
                        <Col span={5}>
                          <Text className="category">
                            {_get(tech, 'usedTechnologyCategory.name') === '開発言語' ||
                            _get(tech, 'usedTechnologyCategory.name') === 'フレームワーク'
                              ? '開発言語\n・フレームワーク'
                              : _get(
                                  tech,
                                  'usedTechnologyCategory.name',
                                  _get(tech, 'otherUsedTechnologyName'),
                                )}
                          </Text>
                        </Col>
                        <Col span={7}>
                          <Text className="name">
                            {_get(tech, 'usedTechnologyName.name') ||
                              _get(tech, 'otherUsedTechnologyName')}
                          </Text>
                        </Col>
                        <Col span={4}>
                          <Text className="level">{_get(tech, 'usedTechnologyLevel.name')}</Text>
                        </Col>
                      </Row>
                    );
                  })}
                {_get(item, 'projectOverview', '') !== '' && (
                  <Row>
                    <Col span={24}>
                      <TextHasReadMore text={_get(item, 'projectOverview', '')} />
                    </Col>
                  </Row>
                )}
              </div>
            </Space>
          </List.Item>
        )}
      />
    </>
  );
};

export default DataList;
