import { customPlugins, installedPlugins } from 'config/plugin';
const routes: any = {};
let saga: any = {};
let slice: any = {};

customPlugins.forEach((pluginName) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const pluginImported = require(`./${pluginName}`);
  routes[pluginName] = pluginImported.routes;
  if (pluginImported.saga) {
    saga = { ...saga, ...pluginImported.saga };
  }
  if (pluginImported.slice) {
    slice = { ...slice, ...pluginImported.slice };
  }
});

for (const pluginName in installedPlugins) {
  routes[pluginName] = installedPlugins[pluginName].routes;
  if (installedPlugins[pluginName].saga) {
    saga = { ...saga, ...installedPlugins[pluginName].saga };
  }
  if (installedPlugins[pluginName].slice) {
    slice = { ...slice, ...installedPlugins[pluginName].slice };
  }
}

const plugins = {
  routes,
  saga,
  slice,
};
export default plugins;
