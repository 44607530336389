import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, Space, Typography } from 'antd';
import type { TextAreaProps } from 'antd/lib/input';
import _get from 'lodash/get';
import type { Control, FieldValues, Path } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { useEffect, useState } from 'react';
import HelperText from '../HelperTextTooltip';

interface FormTextAreaProps<TFormValues extends FieldValues> extends TextAreaProps {
  label?: string;
  className?: string;
  required?: boolean;
  placeholder?: string;
  control?: Control<TFormValues>;
  name: Path<TFormValues>;
  colLabel?: number;
  colWrapper?: number;
  isShowMaxLength?: boolean;
  formLayout?: 'horizontal' | 'vertical';
  helperText?: string;
}

const TextArea = <TFormValues extends FieldValues>({
  label,
  maxLength,
  required,
  control,
  name,
  colLabel,
  colWrapper,
  isShowMaxLength = true,
  formLayout = 'horizontal',
  helperText,
  ...props
}: FormTextAreaProps<TFormValues>) => {
  const [value, setValue] = useState(props.value);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const [isFocus, setIsFocus] = useState(false);

  const formItemLayout = colLabel
    ? {
        labelCol: { span: colLabel },
        wrapperCol: { span: colWrapper || 24 - colLabel },
      }
    : {};

  return (
    <Form.Item
      className={`${formLayout} text-area-item`}
      {...formItemLayout}
      label={
        <Space>
          <Space size={6} style={{ padding: 0 }}>
            {helperText && (
              <>
                <HelperText text={helperText} />
              </>
            )}
            <Typography.Text className={isFocus ? 'on-focus' : ''}>{label}</Typography.Text>
          </Space>
          {required ? (
            <Typography.Text className="required-mark">必須</Typography.Text>
          ) : (
            <Typography.Text className="not-required-mark">任意</Typography.Text>
          )}
        </Space>
      }
      colon={false}
      validateStatus={error ? 'error' : 'success'}
      help={
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <div className="error-text">
            {error && (
              <>
                <InfoCircleOutlined />
                {error.message}
              </>
            )}
          </div>
          {maxLength && isShowMaxLength && (
            <div>
              {_get(field, 'value.length', 0)}/{maxLength}
            </div>
          )}
        </Space>
      }
    >
      <Input.TextArea
        {...props}
        {...field}
        allowClear
        maxLength={maxLength}
        onFocusCapture={() => setIsFocus(true)}
        onBlurCapture={() => setIsFocus(false)}
      />
    </Form.Item>
  );
};

export default TextArea;
