import { useState } from 'react';
import { Typography, Space } from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { useFetchUser, useMutate } from 'hooks';
import useFetchList from 'hooks/useList';

import KnowledgeForm from 'components/skills/KnowledgeForm';
import KnowledgeList from 'components/skills/KnowledgeList';
import skillsQuery from 'models/skills/query';
import { setConfirmModal } from 'providers/GeneralProvider/slice';

import { IListKnowledge, ICertInitValue } from 'models/skills/interface';

import Helper from 'utils/helpers';
import KnowledgeCertificationsForm from 'components/skills/KnowledCertificationForm';
import KnowledgeCertificationsList from 'components/skills/KnowledCertificationList';

const { Title } = Typography;

const KnowledgePage = (): JSX.Element => {
  const { t } = useTranslation();
  const header = {
    title: t('menuBar:knowledge'),
    breadcrumb: {
      routes: [
        {
          path: '',
          breadcrumbName: `${t('menuBar:skills')} / ${t('menuBar:knowledge')}`,
        },
      ],
    },
  };
  const dispatch = useAppDispatch();

  // state
  const [initialValueForm, setInitialValueForm] = useState({});
  const [userCertification, setUserCertification] = useState<any>({});

  const { data } = useFetchUser({ enabled: true });
  const {
    list: knowledgeList,
    isLoading: fetchData,
    refetch: refetchList,
  } = useFetchList<IListKnowledge>({
    ...skillsQuery.knowledgeList,
    customParams: {
      ...skillsQuery.knowledgeList.customParams,
      userId: _get(data, '_id'),
    },
  });

  const { mutateAsync: deleteItem, isLoading: isLoadingDelete } = useMutate<{
    domainKnowledgeExperience: string;
  }>(skillsQuery.deleteKnowledge);

  const {
    list: certificationList,
    isLoading: fetchingCert,
    refetch: refetchCertificationList,
  } = useFetchList<ICertInitValue>(skillsQuery.knowledgeCertificationList);
  const { mutateAsync: deleteCertification, isLoading: isLoadingDelCert } = useMutate<{
    certificationId: string;
  }>(skillsQuery.deleteCertification);

  // scroll to top form section when edit
  const handleScrollDomainKnowledgeForm = () => {
    const element = document.querySelector('.domain-knowledge-scroll');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleScrollDomainKnowledgeCertForm = () => {
    const element = document.querySelector('.domain-knowledge-cert-scroll');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handLeDeleteItem = (id: string) => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          className: 'non-title delete-confirm',
          content: t('description:deleteConfirm'),
          okText: t('button:deleteButton'),
          cancelText: t('button:cancelButton'),
          onOk: () => {
            deleteItem(
              { domainKnowledgeExperience: id },
              {
                onSuccess: () => {
                  Helper.toasts('', t('skills:deleteSuccess'), 'success');
                  resetInitialValue();
                  refetchList();
                },
                onError: (data) => {
                  Helper.toasts('', _get(data, 'message'), 'error');
                },
              },
            );
          },
        },
      }),
    );
  };

  const handleEditItem = (item) => {
    setInitialValueForm(item);
    handleScrollDomainKnowledgeForm();
  };

  // Edit and delete certification
  const handleEditCertification = (item) => {
    setUserCertification(item);
    handleScrollDomainKnowledgeCertForm();
  };

  const handleDeleteCertification = (id) => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          className: 'non-title delete-confirm',
          content: t('description:deleteConfirm'),
          okText: t('button:deleteButton'),
          cancelText: t('button:cancelButton'),
          onOk: () => {
            deleteCertification(
              { certificationId: id },
              {
                onSuccess: () => {
                  refetchCertificationList();
                  resetInitialValueCert();
                  Helper.toasts('', t('skills:deleteSuccess'), 'success');
                },
                onError: (data) => {
                  Helper.toasts('', _get(data, 'message'), 'error');
                },
              },
            );
          },
        },
      }),
    );
  };

  const resetInitialValue = () => {
    setInitialValueForm({});
  };

  const resetInitialValueCert = () => {
    setUserCertification({});
  };

  return (
    <PageContainer header={header}>
      <Space direction="vertical" className="skills-page-wrapper knowledge-page-wrapper">
        <div className="domain-knowledge-scroll"></div>
        <Typography style={{ padding: '0 15px 0 28px', fontWeight: 500 }}>
          担当可能なドメイン知識（業種・業務知識や、組込みシステム毎の知識）とそのレベル・経験年数を登録しましょう。
        </Typography>
        <div className="green-opacity-bg inner-content block-01">
          <Title level={4}>{t('title:domainKnowledgeTitle')}</Title>
          <KnowledgeForm
            initialValue={initialValueForm}
            resetInitialValue={resetInitialValue}
            refreshDataList={refetchList}
          />
          {knowledgeList?.length > 0 && (
            <KnowledgeList
              data={knowledgeList}
              initLoading={fetchData || isLoadingDelete}
              handleEdit={handleEditItem}
              handleDelete={handLeDeleteItem}
            />
          )}
        </div>
        <div className="domain-knowledge-cert-scroll"></div>
        <div className="mt-80 green-opacity-bg inner-content block-01">
          <Title level={4}>{t('title:qualificationTitle')}</Title>
          <KnowledgeCertificationsForm
            initialValue={userCertification}
            resetInitialValue={resetInitialValueCert}
          />
          {certificationList?.length > 0 && (
            <KnowledgeCertificationsList
              data={certificationList}
              initLoading={fetchingCert || isLoadingDelCert}
              handleEdit={handleEditCertification}
              handleDelete={handleDeleteCertification}
            />
          )}
        </div>
      </Space>
    </PageContainer>
  );
};

export default KnowledgePage;
