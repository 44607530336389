import { forwardRef } from 'react';
import { RangePickerBaseProps } from 'antd/es/date-picker/generatePicker';
import moment, { Moment } from 'moment';
import { useController, Control, FieldValues } from 'react-hook-form';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import { DatePicker, Form } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export interface RangeDatePickerProps extends Omit<RangePickerBaseProps<Moment>, 'picker'> {
  label?: string;
  className?: string;
  name: string;
  format?: string;
  required?: boolean;
  control?: Control<FieldValues>;
}
const { RangePicker } = DatePicker;

const RangDatePicker = forwardRef<HTMLInputElement, RangeDatePickerProps>(
  (
    { label, name, size = 'large', className = '', format = 'YYYY-MM-DD', control, ...props },
    inputRef,
  ) => {
    const {
      field: { ref, onChange, onBlur, value },
      fieldState: { error },
    } = useController({
      name,
      control,
      defaultValue: '',
    });

    const handleOnChange = (values, dateString) => {
      onChange(!_isEmpty(dateString[0]) ? dateString : null);
    };

    return (
      <div className={`input-container select-field-container ${className}`}>
        <Form.Item
          label={label}
          validateStatus={error ? 'error' : ''}
          help={
            <div className="helper-wrapper">
              <div className="error-text">
                {error && (
                  <>
                    <InfoCircleOutlined className="info-icon" type="info-circle" />
                    {error.message}
                  </>
                )}
              </div>
            </div>
          }
        >
          <RangePicker
            {...props}
            size={size}
            format={format}
            value={
              typeof value === 'string'
                ? !_isEmpty(value)
                  ? [moment(value.split(',')[0]), moment(value.split(',')[1])]
                  : null
                : !_isEmpty(value)
                ? [moment(value[0]), moment(value[1])]
                : null
            }
            onChange={handleOnChange}
            onBlur={onBlur}
            ref={(e) => {
              ref(e);
              if (inputRef) {
                _set(inputRef, 'current', e);
              }
            }}
          />
        </Form.Item>
      </div>
    );
  },
);

export default RangDatePicker;
