import { Space, Button, List, Typography, Col, Row } from 'antd';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import _get from 'lodash/get';
import { IListKnowledge } from 'models/skills/interface';
import TextHasReadMore from '../TextHasReadMore';
import './style.less';
import '../skills-style.less';

const { Text } = Typography;

interface PropsHeader {
  data: Array<IListKnowledge>;
  className?: string;
  initLoading?: boolean;
  handleEdit: (objectItem: IListKnowledge) => void;
  handleDelete: (objectId: string | any) => void;
}

const KnowledgeList: React.FC<PropsHeader> = ({
  data,
  className,
  initLoading,
  handleEdit,
  handleDelete,
}) => {
  return (
    <List
      className={`skills-list-container skills-list ${className || ''}`}
      itemLayout="horizontal"
      dataSource={data?.slice(0, 20)}
      loading={initLoading}
      renderItem={(item: IListKnowledge) => (
        <List.Item
          actions={[
            <Button
              className="btn-delete"
              shape="circle"
              icon={<CloseRoundedIcon />}
              onClick={() => handleDelete(_get(item, '_id'))}
            />,
            <Button
              className="btn-edit"
              shape="circle"
              icon={<CreateRoundedIcon />}
              onClick={() => handleEdit(item)}
            />,
          ]}
          key={item._id}
        >
          <Space className="content-item" direction="vertical">
            <Row justify="start">
              <Col span={10}>
                <Text className="roles">{_get(item, 'industry.item')}</Text>
              </Col>
              <Col span={4}>
                <Text className="level">{_get(item, 'level.name')}</Text>
              </Col>
              <Col span={4}>
                <Text className="experienceYears">{item.experienceYears}年</Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <TextHasReadMore text={_get(item, 'detail', '')} />
              </Col>
            </Row>
          </Space>
        </List.Item>
      )}
    />
  );
};

export default KnowledgeList;
