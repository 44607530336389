import { useHistory, useLocation } from 'react-router';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import HeaderComponent from 'components/Header';
import GreenBox from 'components/GreenBox';
import './styles.less';
import Helper from 'utils/helpers';

const ResetPassWordNotificationPage = (): JSX.Element => {
  const locationParams = useLocation();
  const history = useHistory();

  if (Helper.getAuthToken()) {
    history.push('/dashboard');
  }
  if (_isEmpty(_get(locationParams, 'state.email', ''))) {
    history.push('/');
  }
  return (
    <div>
      <HeaderComponent hasLoginButton />
      <div className="reset-pwd-noti-wrapper">
        <GreenBox title="パスワード再設定メール送信完了">
          <>
            <div className="email-state">{_get(locationParams, 'state.email', '')}</div>
            <div>
              ご登録いただいたメールアドレスにパスワード再設定メールを送信致しました。
              <br /> メール内のURLをクリックしてパスワードを再設定してください。
            </div>
          </>
        </GreenBox>
      </div>
    </div>
  );
};

export default ResetPassWordNotificationPage;
