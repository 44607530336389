import type { ReactNode } from 'react';
import { useFetchList, useFetchUser } from 'hooks';
import resourceQuery from 'models/resource/query';

const DataProvider = ({ children }: { children?: ReactNode }) => {
  useFetchUser({ enabled: true });
  useFetchList(resourceQuery.prefecture);
  useFetchList(resourceQuery.city);
  useFetchList(resourceQuery.country);
  useFetchList(resourceQuery.line);
  return <>{children}</>;
};

export default DataProvider;
