import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
import TextArea from 'components/Form/TextArea';
import { IWorkEnvironment } from 'models/work-environment/interface';
import workEnvironmentQuery from 'models/work-environment/query';
// hooks
import { useMutate } from 'hooks';
import Helper from 'utils/helpers';

import FormSchema from './validate';

import '../workEnvironment-style.less';

type FormValues = InferType<typeof FormSchema>;

const WorkingHoursForm: React.FC<{
  initialValue?: Record<string, undefined>;
  updateData?: any;
}> = ({ initialValue, updateData }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { t } = useTranslation();

  const { mutateAsync: update, isLoading } = useMutate<IWorkEnvironment>(
    workEnvironmentQuery.updateWorkEnvironment,
  );

  const handleFormSubmit = (values: FormValues) => {
    update(
      {
        ...values,
        choiceOfWorkingHours: values.choiceOfWorkingHours,
      },
      {
        onSuccess: (data) => {
          Helper.toasts('', t('skills:createSuccess'), 'success');
          updateData();
        },
        onError: (err) => {
          Helper.toasts('', _get(err, 'message'), 'error');
        },
      },
    );
  };
  useEffect(() => {
    // setInitialForm(_get(initialValue, 'choiceOfWorkingHours', ''));
    reset({
      choiceOfWorkingHours: initialValue?.choiceOfWorkingHours,
    });
  }, [initialValue]);

  return (
    <form className="working-hours-form-container" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="form-block">
        <div className="row-block full-row">
          <TextArea
            name="choiceOfWorkingHours"
            control={control}
            placeholder={t('placeholder:fieldDetail')}
            maxLength={2000}
            isShowMaxLength={false}
            rows={8}
            showCount={true}
            colLabel={2}
            colWrapper={24}
            formLayout="vertical"
          />
        </div>

        <div className="submit-btn-wrapper">
          <Button htmlType="submit" className="second-color" type="primary" loading={isLoading}>
            {t('button:saveButton')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default WorkingHoursForm;
