import React, { useState } from 'react';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import TechnologiesForm from 'components/workEnvironment/TechnologiesForm';
import TechnologiesList from 'components/workEnvironment/TechnologiesList';
import { setConfirmModal } from 'providers/GeneralProvider/slice';

import { useAppDispatch } from 'store';
import useFetchList from 'hooks/useList';
import useMutate from 'hooks/useMutate';
import Helper from 'utils/helpers';
import workEnvironmentQuery from 'models/work-environment/query';
import { ITechnologies, IUpdateTechnologies } from 'models/work-environment/interface';

import './styles.less';

const TechnologiesPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [initialValueForm, setInitialValueForm] = useState({});

  const {
    list: dataList,
    isLoading: fetchData,
    refetch: refetchList,
  } = useFetchList<ITechnologies>(workEnvironmentQuery.technologiesList);

  const { mutateAsync: deleteItem, isLoading: isLoadingDelete } = useMutate<{
    technology: string;
  }>(workEnvironmentQuery.deleteTechnologies);

  const resetInitialValue = () => {
    setInitialValueForm({});
  };
  // scroll to top form section when edit
  const handleScrollWorkTechnicalForm = () => {
    const element = document.querySelector('.work-technical-scroll');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleEditItem = (item: IUpdateTechnologies) => {
    setInitialValueForm(item);
    handleScrollWorkTechnicalForm();
  };

  const handLeDeleteItem = (id: string) => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          className: 'non-title delete-confirm',
          content: t('description:deleteConfirm'),
          okText: t('button:deleteButton'),
          cancelText: t('button:cancelButton'),
          onOk: () => {
            deleteItem(
              { technology: id },
              {
                onSuccess: () => {
                  Helper.toasts('', t('skills:deleteSuccess'), 'success');
                  resetInitialValue();
                  refetchList();
                },
                onError: (data) => {
                  Helper.toasts('', _get(data, 'message'), 'error');
                },
              },
            );
          },
        },
      }),
    );
  };

  return (
    <div className="technologies-page-wrapper">
      <TechnologiesForm
        initialValue={initialValueForm}
        resetInitialValue={resetInitialValue}
        refreshDataList={refetchList}
      />

      {dataList.length > 0 && (
        <TechnologiesList
          data={dataList}
          handleEdit={handleEditItem}
          handleDelete={handLeDeleteItem}
        />
      )}
    </div>
  );
};

export default TechnologiesPage;
