/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const introductionsQuery = {
  introductionItemList: {
    apiUrl: '/v1/selfIntroduction/item/list',
    queryKey: ['public', 'introductionList'],
    customParams: {
      perPage: 20,
      sort: 'createdAt@asc',
    },
    staleTime: Infinity,
  },
  createIntroduction: {
    apiUrl: '/v1/selfIntroduction/item/create',
  },

  updateIntroductionItem: {
    apiUrl: ({ selfIntroductionItem }: { selfIntroductionItem: string }) =>
      `/v1/selfIntroduction/item/update/${selfIntroductionItem}`,
    method: 'put',
  },
  deleteIntroduction: {
    apiUrl: ({ id }: { id: string }) => `/v1/selfIntroduction/item/delete/${id}`,
    method: 'delete',
    mode: 'cors',
  },

  updateSelfIntroduction: {
    apiUrl: '/v1/selfIntroduction/update ',
  },
  getSelfIntroduction: {
    apiUrl: '/v1/selfIntroduction/get ',
    queryKey: ['self-introduction', 'future-goal'],
  },
};

export default introductionsQuery;
