import React from 'react';
import { PageContainer } from '@ant-design/pro-layout';
// import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button, Collapse } from 'antd';
import Icon from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Header from 'components/Header';
import './styles.less';
import Helper from 'utils/helpers';
import { ReactComponent as MinusIcon } from 'assets/images/icon-minus.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icon-plus.svg';
import ReasonContent from 'assets/images/home/reason-content@2x.png';
import FeaturesContent from 'assets/images/home/features-content@2x.png';
import WhatContent from 'assets/images/home/what-content@2x.png';
import SolutionContent from 'assets/images/home/solution-content@2x.png';
import ProblemsContent from 'assets/images/home/problems-content@2x.png';
import useFetchList from 'hooks/useList';
import { ISettingListData } from 'models/setting/interface';
import settingQuery from 'models/setting/query';

const faq = [
  {
    question: 'Experportをどのような人が利用しているのか？',
    answers: [
      'Experportを利用しているのは、SIer業界およびSES業界の会社に所属しているソフトウェアエンジニアです。',
    ],
  },
  {
    question: '経験・スキルに関する情報は従来のExcel/Wordの経歴書と比べて何が違うのか？',
    answers: [
      '・Experportはエンジニアリングを進めるうえでの重要要素(役割・技術・ドメイン知識)について、レベル・経験年数・詳細情報を明確に示す点で従来のExcel/Word経歴書と異なっています。',
      '・ご自身の経験・スキルの棚卸しや振り返りに役立ちます。',
      '・顧客候補がエンジニアの経験・スキルを具体的に理解しやすくなり、憶測で判断することを避けることができます。',
    ],
  },
  {
    question: 'Aperportとの連携の必要性は？',
    answers: [
      'Experportはエンジニア向けのポートフォリオ作成サービスであるため、Aperportと連携することは必須ではありません。',
      'しかし、作成したポートフォリオは自分にとってよりマッチング度の高い案件へのアサインを実現するために活用していただきたいため、Aperportとの連携をおすすめしています。',
      'これにより、所属会社が会社として持っているネットワークを最大限活用できるようになるため、所属会社としても営業を行いやすくなります。',
    ],
  },
  {
    question: '利用する際に、料金はかかるのか？',
    answers: ['エンジニアがExperportを利用するのに料金はかかりません。'],
  },
  {
    question: '登録したポートフォリオはどこまで公開されるのか？',
    answers: [
      '・ポートフォリオを登録しただけでは、他の人に内容を閲覧されることはありません。',
      '・所属会社が利用するAperport(アペルポート)にポートフォリオ情報の連携を行い、所属会社の営業担当がポートフォリオ情報を「公開」にしない限り、他の人には閲覧されません。',
      '・なお、「公開」の範囲は、インターネット上で広く公開されるものではなく、Aperport利用者の間における限定的な公開になります。',
    ],
  },
];

const HomePage: React.FC = () => {
  const { push } = useHistory();
  const { list: settingList } = useFetchList<ISettingListData>(settingQuery.settingList);
  const termsOfUseLink =
    settingList.find((setting) => setting.name === 'termsOfUseLink')?.value || '';
  const privacyPolicyLink =
    settingList.find((setting) => setting.name === 'privacyPolicyLink')?.value || '';
  const dataPolicyLink =
    settingList.find((setting) => setting.name === 'dataPolicyLink')?.value || '';
  const operatingCompanyLink =
    settingList.find((setting) => setting.name === 'operatingCompanyLink')?.value || '';
  const seeMoreLink = settingList.find((setting) => setting.name === 'seeMoreLink')?.value || '';

  if (Helper.getAuthToken()) {
    push('/dashboard');
  }

  return (
    <>
      <Header hasLoginButton hasRegisterButton className="header-landing-page" />
      <PageContainer>
        <div className="home-page-wrapper">
          <div id="sectionTop" className="section-top">
            <div className="top-container">
              <div>ポートフォリオ作成サービスのExperportは</div>
              <div className="color-text">エンジニアのキャリア構築を支援します</div>
            </div>
            <Link to={'/pre-check-email-sign-up'}>
              <Button type="primary" className="second-color">
                会員登録
              </Button>
            </Link>
          </div>
          <div id="problems" className="section-problems">
            <div className="c-title">
              <div className="en">
                <em>Problems</em>faced by engineer
              </div>
              <h2 className="jp">エンジニアのキャリア構築における主要な課題</h2>
            </div>
            <img
              src={ProblemsContent}
              alt="エンジニアのキャリア構築における主要な課題"
              className="problems-content"
            />
          </div>
          <div id="solution" className="section-solution">
            <div className="c-title">
              <div className="en">
                <em>Solution</em>
              </div>
              <h2 className="jp">解決法</h2>
            </div>
            <div className="solution-container">
              <p>
                Experport（エクスパポート）は、エンジニアと所属会社が将来の顧客候補に対して
                <br />
                適切な情報提供を行うことを支援します。
              </p>
              <p>
                また、マッチングサービスであるAperportへの情報連携を行うことで、
                <br />
                顧客候補はプロジェクトとエンジニアのマッチング度を数値で確認することをできます。
              </p>
              <p>
                最終的に、エンジニアは挑戦できる仕事、柔軟性のある働き方を実現できる仕事、
                <br />
                適正な評価を得られる仕事などにアサインされる可能性を高めることができます。
              </p>
              <img src={SolutionContent} alt="解決法" className="solution-content" />
            </div>
          </div>
          <div id="definition" className="section-definition">
            <div className="c-title">
              <div className="en">
                <em>What is</em>Experport
              </div>
              <h2 className="jp">Experportとは</h2>
            </div>
            <div className="definition-container">
              <img src={WhatContent} alt="Experportとは" className="definition-img-content" />
              <div>
                <span>Experportは以下の点を実現できます。 </span>
                <br />
                <ol>
                  <li>スキルや経験を端的および詳細に伝えることができる</li>
                  <li>
                    ウェブ上のポートフォリオを個別に連絡した顧客・顧客候補の範囲内のみで公開可能
                  </li>
                  <li>同僚や顧客からの推薦情報を蓄積し、ポートフォリオの一部としてアピール可能</li>
                </ol>
              </div>
              <div>
                <span>
                  また、Experportを所属会社が利用しているAperportと連携させることで、以下のことも実現できます。
                </span>
                <br />
                <ol>
                  <li>
                    スキルや経験の情報を対象にして、将来の顧客候補からAperportサービス内で検索される
                  </li>
                  <li>従来よりも多くの顧客候補に対してアピールすることができる</li>
                  <li>顧客候補の疑問に合わせてポートフォリオの柔軟な改善が可能</li>
                </ol>
              </div>
            </div>
          </div>
          <div id="features" className="section-features">
            <div className="c-title">
              <div className="en">
                Main<em>features</em>
              </div>
              <h2 className="jp">Experportの機能紹介</h2>
            </div>
            <img src={FeaturesContent} alt="Experportの機能紹介" className="features-content" />
          </div>
          <div id="reason" className="section-reason">
            <div className="c-title">
              <div className="en">
                <em>Reason</em>to choose
              </div>
              <h2 className="jp">選ばれる理由</h2>
            </div>
            <img src={ReasonContent} alt="選ばれる理由" className="reason-content" />
          </div>
          <div id="faq" className="section-faq">
            <div className="c-title">
              <div className="en">
                Frequently Asked<em>Questions</em>
              </div>
              <h2 className="jp">よくある質問</h2>
            </div>
            <div className="faq-container">
              {faq.map((qa, idx) => (
                <div key={`faq-${idx}`}>
                  <div className="faq-divider"> </div>
                  <Collapse
                    ghost
                    collapsible="header"
                    expandIconPosition="end"
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <Icon component={MinusIcon} size={40} style={{ fontSize: 20 }} />
                      ) : (
                        <Icon component={PlusIcon} style={{ fontSize: 20 }} />
                      )
                    }
                  >
                    <Collapse.Panel
                      header={
                        <div>
                          <span className="faq-letter-q">Q</span>
                          <span className="faq-question">{qa.question}</span>
                        </div>
                      }
                      key={`key${idx}`}
                    >
                      <div className="faq-answer">
                        {qa.answers?.map((ans: string, idx: number) => (
                          <div className="faq-answer-line" key={`faq-ans-${idx}`}>
                            {ans}
                          </div>
                        ))}
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              ))}
              <div className="faq-divider"> </div>
              <div className="faq-see-more">
                <a href={seeMoreLink || '/'} target="_blank" rel="noreferrer">
                  {'もっとみる >'}
                </a>
              </div>
            </div>
          </div>
          <div className="section-footer">
            <div>
              <a
                href={termsOfUseLink || '/'}
                target="_blank"
                className="footer-link"
                rel="noreferrer"
              >
                利用規約
              </a>
              <span className="footer-divider"></span>
              <a
                href={dataPolicyLink || '/'}
                target="_blank"
                className="footer-link"
                rel="noreferrer"
              >
                データ取扱規則
              </a>
              <span className="footer-divider"></span>
              <a
                href={privacyPolicyLink || '/'}
                target="_blank"
                className="footer-link"
                rel="noreferrer"
              >
                プライバシーポリシー
              </a>
              <span className="footer-divider"></span>
              <a
                href={operatingCompanyLink || '/'}
                target="_blank"
                className="footer-link"
                rel="noreferrer"
              >
                運営会社
              </a>
            </div>
            <br />
            <div className="footer-copyright">Copyright © 2023</div>
          </div>
        </div>
      </PageContainer>
    </>
  );
};

export default HomePage;
