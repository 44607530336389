import { boolean, object, string } from 'yup';

const IntroductionSchema = object({
  memo: string()
    .trim()
    .required('利用シーン・メッセージの相手など入力してください。')
    .max(100, '入力文字数が上限に達しました。'),
  selfIntroductionDetail: string().trim().required('自己紹介詳細を入力してください。'),
  linkToAP: boolean(),
});

export default IntroductionSchema;
