import { object, string } from 'yup';

const FormSchema = object({
  category: string().required('技術カテゴリを選択してください。'),
  skillName: string().required('技術名を選択してください。'),
  otherSkillName: string().trim().required('技術名を入力してください。'),
  choose: string().required('レベルを選択してください。'),
});

export default FormSchema;
