import { PageContainer } from '@ant-design/pro-layout';
import { useHistory } from 'react-router-dom';
import Helper from 'utils/helpers';
import './styles.less';
import guide1 from 'assets/images/icon-1.svg';
import guide2 from 'assets/images/icon-2.svg';
import guide3 from 'assets/images/icon-3.svg';
import aperport from 'assets/images/aperport.svg';
import dropdownIcon from 'assets/images/icon_24_dropdown.svg';
import cooperation from 'assets/images/cooperation@3x.png';

const DashboardPage = (): JSX.Element => {
  const history = useHistory();

  if (!Helper.getAuthToken()) {
    history.push('/');
  }

  return (
    <PageContainer title="Dashboard Page" className="dashboard-wrapper">
      <div className="howto-guide">
        <div className="guide-details">
          <p className="step">
            <img src={guide1} alt="自己紹介" />
          </p>
          <p className="inner">
            <h4>自己紹介</h4>
            <p className="text">
              自己紹介文を登録しましょう。
              <br />
              利用するシーンや、 紹介をする相手に応じてメッセージを変えてみましょう。
            </p>
          </p>
        </div>
        <div className="guide-details">
          <p className="step">
            <img src={guide2} alt="経験・スキル" />
          </p>
          <p className="inner">
            <h4>経験・スキル</h4>
            <p className="text">
              経験・スキルを登録しましょう。
              <br />
              詳しければ詳しいほどマッチング度が高まります。
            </p>
          </p>
        </div>
        <div className="guide-details">
          <p className="step">
            <img src={guide3} alt="職場環境選好" />
          </p>
          <p className="inner">
            <h4>職場環境選好</h4>
            <p className="text">
              最適な職場環境を見つけるよう
              <br />
              選好を登録しましょう。
            </p>
          </p>
        </div>
      </div>
      <div style={{ position: 'relative', top: '-68px' }}>
        <div className="top-about">
          <img src={aperport} alt="aperport" />
          <p className="inner">
            ExperportのポートフォリオはAperportに連携可能です。
            <br />
            Aperportはエンジニアとプロジェクトのマッチングサービスです。
            <br />
            営業担当者から連携許可依頼が来たら許可してください。
            <br />
            入力は全て完了していなくてもOKです。連携後に順次入力も可能です。
          </p>
        </div>
        <div className="arrow-icon">
          <img src={dropdownIcon} alt="" />
        </div>
        <div className="cooperation-wrapper">
          <h3>
            Aperportとの連携を通して、
            <br />
            多くの人にご自身のことを知ってもらおう
          </h3>
          <img
            src={cooperation}
            alt="Aperportとの連携を通して、多くの人にご自身のことを知ってもらおう"
          />
        </div>
      </div>
    </PageContainer>
  );
};

export default DashboardPage;
