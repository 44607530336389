import { call } from 'redux-saga/effects';
import _get from 'lodash/get';
import { toast } from 'react-toastify';
import Helper from 'utils/helpers';
import { ERRORS } from 'utils/constants';

export class ApiError {
  message: string;

  code: number;

  constructor(error: { message: string; code: number }) {
    this.message = error.message;
    this.code = error.code;
  }
}

export function* callApi<T1 extends unknown[]>(
  fn: (...fnArgs: unknown[]) => void,
  ...args: T1
): Generator<unknown> {
  try {
    return yield call(fn, ...args);
  } catch (error) {
    const errorCode = _get(error, 'code');
    const errorMessage = _get(error, 'message');
    // toast.error(typeof errorMessage === 'string' ? errorMessage : '', {
    //   toastId: typeof errorMessage === 'number' ? errorCode : '',
    // });
    // Helper.toasts('', errorMessage, 'error');
    // NOTE: Replace with your server's code
    if (errorCode === ERRORS.INVALID_SESSION_TOKEN.code) {
      Helper.removeAuthToken();
      window.location.href = '/';
    }
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw new ApiError(error);
  }
}
