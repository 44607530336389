import { PageContainer } from '@ant-design/pro-layout';
import { Space, Typography, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { useFetchDetail } from 'hooks';
import headImage from 'assets/images/self-introduction-icon.svg';
import CollapseBox from 'components/Collapse';
import WorkingHoursForm from 'components/workEnvironment/WorkingHoursForm';
import WorkingPlaceForm from 'components/workEnvironment/WorkingPlaceForm';
import DesiredRoleForm from 'components/workEnvironment/DesiredRoleForm';
import DesiredProjectForm from 'components/workEnvironment/DesiredProjectForm';
import useFetchList from 'hooks/useList';
import settingQuery from 'models/setting/query';
import { IHelpTextData } from 'models/setting/interface';
import workEnvironmentQuery from 'models/work-environment/query';
import { IWorkEnvironment } from 'models/work-environment/interface';
import TechnologiesPage from './TechnologiesPage';
import './styles.less';

const { Text } = Typography;

const PrivateInformationPage = (): JSX.Element => {
  const { t } = useTranslation();
  const header = {
    title: t('menuBar:workEnvironment'),
    breadcrumb: {
      routes: [
        {
          path: '',
          breadcrumbName: `${t('menuBar:workEnvironment')}`,
        },
      ],
    },
  };
  // get information
  const {
    list,
    refetch: fetchingWorkEnvironmentData,
    isLoading,
  } = useFetchList<IWorkEnvironment>(workEnvironmentQuery.workEnvironment);

  const { data: dataHelpText } = useFetchDetail<IHelpTextData>({
    queryKey: ['getHelpText'],
    apiUrl: settingQuery.getHelpText.apiUrl,
  });

  return (
    <PageContainer header={header}>
      <Space
        style={{ width: '660px' }}
        direction="vertical"
        className="private-information-page-wrapper"
      >
        <Typography style={{ padding: '0 16px 0 32px', fontWeight: 500 }}>
          経験・スキル、性格、志向性を踏まえて、ご自身がパフォーマンスを発揮しやすい職場の好み（選好）を伝えましょう。
        </Typography>

        <Spin spinning={isLoading}>
          <CollapseBox title="希望する役割" defaultActiveKey={['collapse1']}>
            <img src={headImage} alt="icon" />
            <DesiredRoleForm
              dataSource={_get(list, 'roles', [])}
              updateData={fetchingWorkEnvironmentData}
            />
          </CollapseBox>
          {/* <CollapseBox title="希望する役割と自身の性格の関係" defaultActiveKey={['collapse1']}>
            <Space direction="vertical" className="personality-block">
              <Text>
                ご自身の性格を伝えてください。開発現場でご自身が担う役割を意識して、その役割を全うするためにご自身の性格が役立つことが読み手に伝わる文章が理想です。
              </Text>
              <RolePersonalityForm
                initialValue={{ roleFittingPersonality: _get(list, 'roleFittingPersonality') }}
                updateData={fetchingWorkEnvironmentData}
              />
            </Space>
            <Space direction="vertical" className="personality-block">
              <Title level={4}>性格に関する担当営業のコメント</Title>
              <Text style={{ display: 'block', marginTop: '24px' }}>
                ご自身の性格を説明する立場にある所属会社担当営業などのコメントを登録しましょう。
              </Text>
              <CommentOfSalesForm
                initialValue={{ commentOfSales: _get(list, 'commentOfSales') }}
                updateData={fetchingWorkEnvironmentData}
              />
            </Space>
          </CollapseBox> */}
          {/* scroll here when edit technologies page */}
          <div className="work-technical-scroll"></div>
          <CollapseBox
            title="希望する使用技術"
            defaultActiveKey={['collapse1']}
            helperText={_get(
              dataHelpText,
              'value.formHelpTextWorkEnvironmentPage.technicalSkillPreference',
            )}
          >
            <Text style={{ display: 'block', marginTop: '12px', marginBottom: '24px' }}>
              近い将来に参画するプロジェクトで使用したい技術を伝えてください。
            </Text>
            <TechnologiesPage />
          </CollapseBox>
          <CollapseBox
            title="希望するプロジェクトの特徴"
            defaultActiveKey={['collapse1']}
            helperText={_get(dataHelpText, 'value.formHelpTextWorkEnvironmentPage.desired')}
          >
            <DesiredProjectForm
              dataSource={_get(list, 'desiredProjectFeatures', [])}
              updateData={fetchingWorkEnvironmentData}
            />
          </CollapseBox>
          <CollapseBox
            title="働く時間の好み"
            defaultActiveKey={['collapse1']}
            helperText={_get(
              dataHelpText,
              'value.formHelpTextWorkEnvironmentPage.choiceOfWorkingHours',
            )}
          >
            <Text style={{ display: 'block', marginTop: '12px' }}>
              ご自身が仕事で高いパフォーマンスを発揮しやすい時間帯を伝えましょう。希望通りの案件が見つかる保証はありませんが、現場責任者が配慮してくれる可能性があります。
            </Text>
            <WorkingHoursForm
              initialValue={{ choiceOfWorkingHours: _get(list, 'choiceOfWorkingHours') }}
              updateData={fetchingWorkEnvironmentData}
            />
          </CollapseBox>
          <CollapseBox
            title="オフィス環境の好み"
            defaultActiveKey={['collapse1']}
            helperText={_get(
              dataHelpText,
              'value.formHelpTextWorkEnvironmentPage.choiceOfWorkingPlaces',
            )}
          >
            <Text style={{ display: 'block', marginTop: '12px' }}>
              ご自身が仕事で高いパフォーマンスを発揮しやすいオフィス環境を伝えましょう。希望通りの案件が見つかる保証はありませんが、現場責任者が配慮してくれる可能性があります。
            </Text>
            <WorkingPlaceForm
              initialValue={{ choiceOfWorkingPlace: _get(list, 'choiceOfWorkingPlace') }}
              updateData={fetchingWorkEnvironmentData}
            />
          </CollapseBox>
        </Spin>
      </Space>
    </PageContainer>
  );
};

export default PrivateInformationPage;
