/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Input, Form } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import _get from 'lodash/get';
import { InputProps } from 'antd/lib/input';
import { useController, Path, Control, FieldValues } from 'react-hook-form';
import './style.less';
import { useState } from 'react';
import HelperText from 'components/Form/HelperTextTooltip';

interface TextInputProps<TFormValues> extends InputProps {
  label?: string;
  noLabel?: boolean;
  className?: string;
  inputType?: 'password' | 'textarea' | 'search' | undefined;
  required?: boolean;
  placeholder?: string;
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  helperText?: string;
  isHidden?: boolean;
}

const TextInput = <TFormValues extends FieldValues>({
  label,
  noLabel,
  maxLength,
  inputType,
  allowClear = false,
  className = '',
  required,
  control,
  name,
  helperText,
  isHidden,
  ...props
}: TextInputProps<TFormValues>) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const isError = error;
  const [isFocus, setIsFocus] = useState(false);
  const renderInput = () => {
    if (inputType === 'password') {
      return (
        <Input.Password
          {...props}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          onFocus={() => setIsFocus(true)}
          onBlurCapture={() => setIsFocus(false)}
        />
      );
    }
    if (inputType === 'search') {
      return <Input.Search onChange={onChange} onBlur={onBlur} value={value} {...props} />;
    }
    return (
      <Input
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...props}
        onFocus={() => setIsFocus(true)}
        onBlurCapture={() => setIsFocus(false)}
        allowClear
        ref={ref}
      />
    );
  };
  return (
    <div className={`input-container text-field-container ${className}`}>
      {label && (
        <div className="label-wrapper">
          {required && !noLabel ? (
            <div className="required-mark">必須</div>
          ) : (
            <div className="not-required-mark">任意</div>
          )}
          <div className={`${isFocus ? 'on-focus-input' : 'label'}`}>{label}</div>
          {helperText && <HelperText text={helperText} />}
        </div>
      )}
      {noLabel && <div className="no-label"> </div>}
      <Form.Item
        validateStatus={isError ? 'error' : 'success'}
        help={
          <div className="helper-wrapper">
            <div className="error-text">
              {isError && (
                <>
                  <InfoCircleOutlined className="info-icon" type="info-circle" />
                  {error.message}
                </>
              )}
            </div>
            {maxLength && (
              <div className="max-length-text">
                {_get(value, 'length', 0)}/{maxLength}
              </div>
            )}
          </div>
        }
      >
        {renderInput()}
      </Form.Item>
    </div>
  );
};

export default TextInput;
