/**
 * Custom plugin is plugin that you can customize by yourself
 *
 * Example: customPlugins=['example-custom-plugin']
 */
export const customPlugins = [];

/**
 * Installed plugin is plugin that you will install via npm
 *
 * Example: installedPlugins={
 *   'admin-console-plugin-booking': require('admin-console-plugin-booking'),
 * }
 */
export const installedPlugins = {
  // 'admin-console-plugin-booking': require('admin-console-plugin-booking'),
  // 'admin-console-plugin-earning': require('admin-console-plugin-earning'),
  // 'admin-console-plugin-provider': require('admin-console-plugin-provider'),
  // 'admin-console-plugin-customer': require('admin-console-plugin-customer'),
  // 'admin-console-plugin-services': require('admin-console-plugin-services'),
  // 'admin-console-plugin-system-user': require('admin-console-plugin-system-user'),
  // 'admin-console-plugin-activity-log': require('admin-console-plugin-activity-log'),
};

/**
 * This is used for config api url endpoint of installed plugins
 *
 * You can review README.md of each plugins to see how to add api endpoint here
 *
 * Example:
 *  export const installedPluginApiUrl = {
 *    booking: {
 *      list: '/admin/bookings',
 *    },
 *  };
 */
export const installedPluginApiUrl = {
  // booking: {
  //   list: '/account/admin/consumers',
  //   detail: '/account/admin/consumers/:id',
  //   create: '/account/admin/create',
  //   edit: '/account/admin/update/:id',
  //   exportCSV: '/account/admin/export',
  //   changeStatus: '/account/admin/updatestatus/:id',
  //   duplicate: '/account/admin/duplicate',
  // },
  // activityLog: {
  //   list: '/account/admin/activityLog',
  // },
  // customer: {
  //   list: '/account/admin/customer',
  //   detail: '/account/admin/customer/:id',
  //   exportCSV: '/account/admin/export',
  //   changeStatus: '/account/admin/updatestatus/:id',
  //   delete: '/account/admin/delete-customer/:id',
  //   create: '/account/admin/create-customer',
  //   edit: '/account/admin/edit-customer/:id',
  // },
  // service: {
  //   list: '/admin/service',
  //   create: '/admin/service/create',
  //   edit: '/admin/service/update/:id',
  //   delete: '/admin/service/delete/:id',
  //   changeStatus: '/admin/service/updatestatus/:id',
  //   changeOrderService: '/admin/service/changeOrderService',
  // },
  // earning: {
  //   list: '/admin/earnings',
  //   detail: '/admin/earnings/:id',
  //   exportCSV: '/admin/earnings-export',
  //   exportTransactionCSV: '/admin/transaction-export',
  //   payoutCycleTimeList: '/admin/payoutCycleTimeList',
  //   transationList: '/admin/transactions',
  // },
  // provider: {
  //   getBankList: '/banks',
  //   getBranchList: '/banks/:id/branches',
  // },
};
