import { useEffect, FunctionComponent } from 'react';
import { useHistory, Route, RouteComponentProps } from 'react-router-dom';
import Helper from 'utils/helpers';
import DashboardPage from 'containers/Layout';

interface PrivateRoutesProps {
  exact?: boolean;
  path: string;
  component: FunctionComponent<RouteComponentProps>;
}

const PrivateRoutes = ({ exact, path, component }: PrivateRoutesProps): JSX.Element => {
  const history = useHistory();
  const token = Helper.getAuthToken();
  useEffect(() => {
    if (!token) {
      history.push('/login');
    }
  }, [token, history]);
  return (
    <DashboardPage path={path}>
      <Route exact={exact} path={path} component={component} />
    </DashboardPage>
  );
};

export default PrivateRoutes;
