import { put, takeEvery, takeLeading } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import data from 'data/notification-data.json';
import { getList } from 'utils/mock-server';
import {
  // PLOP: Add slice import below -- DO NOT ERASE
  getNotificationListRequest,
  getNotificationListSuccess,
  getNotificationListError,
  markReadAllRequest,
  markReadAllSuccess,
  markReadAllError,
  markReadItemRequest,
  markReadItemSuccess,
  markReadItemError,
} from './slice';

// PLOP: Add worker export below -- DO NOT ERASE
export function* handleGetNotificationList(action: AnyAction): unknown {
  try {
    const filters = ['status'];
    const sorter = {
      id: 'string',
      displayName: 'string',
      name: 'string',
    };
    const res = yield getList(data as any, action.payload, filters, sorter);
    yield put(getNotificationListSuccess(res));
  } catch (error) {
    yield put(getNotificationListError());
  }
}

export function* handleMarkReadAll(action: AnyAction): unknown {
  try {
    yield put(markReadAllSuccess(null));
  } catch (error) {
    yield put(markReadAllError());
  }
}

export function* handleMarkReadItem(action: AnyAction): unknown {
  try {
    yield put(markReadItemSuccess(action.payload));
  } catch (error) {
    yield put(markReadItemError());
  }
}

export default function* notificationWatch(): Generator {
  // PLOP: Add fork below -- DO NOT ERASE
  yield takeEvery(getNotificationListRequest.type, handleGetNotificationList);
  yield takeEvery(markReadAllRequest.type, handleMarkReadAll);
  yield takeEvery(markReadItemRequest.type, handleMarkReadItem);
}
