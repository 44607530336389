import { Collapse } from 'antd';
import { CollapseProps } from 'antd/lib/collapse';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import './style.less';
import HelperText from 'components/Form/HelperTextTooltip';

const { Panel } = Collapse;

interface PropsCollapseBox extends CollapseProps {
  collapsible?: any;
  title: string;
  children: React.ReactNode;
  helperText?: string;
}
const CollapseBox: React.FC<PropsCollapseBox> = ({
  collapsible,
  title,
  children,
  helperText,
  ...props
}) => (
  <Collapse
    className={`collapse-wrapper`}
    bordered={false}
    expandIconPosition="end"
    collapsible={collapsible}
    expandIcon={({ isActive }) => <KeyboardArrowDownRoundedIcon rotate={isActive ? 90 : 0} />}
    {...props}
  >
    <Panel
      header={
        <>
          <span>{title}</span>
          {helperText && <HelperText text={helperText} />}
        </>
      }
      key="collapse1"
    >
      {children}
    </Panel>
  </Collapse>
);

export default CollapseBox;
