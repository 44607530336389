/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
import SelectField from 'components/Form/SelectNew';
import TextInput from 'components/Form/TextInputNew';
import TextArea from 'components/Form/TextArea';

// hooks
import useFetchList from 'hooks/useList';
import { useMutate, useFetchUser, useFetchDetail } from 'hooks';
import resourceQuery from 'models/resource/query';
import skillsQuery from 'models/skills/query';
import settingQuery from 'models/setting/query';
import { IHelpTextData } from 'models/setting/interface';
import Helper from 'utils/helpers';
import './style.less';
import '../skills-style.less';

import { IListKnowledge, IUpdateKnowledge, ICreateKnowledge } from 'models/skills/interface';
import { IIndustryItemGroup, ILevelItem } from 'models/resource/interface';
import FormSchema from './validate';

type FormValues = InferType<typeof FormSchema>;

const KnowledgeForm: React.FC<{
  initialValue: IListKnowledge | Record<string, unknown>;
  resetInitialValue: () => void;
  refreshDataList: () => void;
}> = ({ initialValue, resetInitialValue, refreshDataList }) => {
  // state
  const [initialForm, setInitialForm] = useState({});
  const { data: dataHelpText } = useFetchDetail<IHelpTextData>({
    queryKey: ['getHelpText'],
    apiUrl: settingQuery.getHelpText.apiUrl,
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    setValue,
    clearErrors,
  } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: initialForm,
  });
  const { t } = useTranslation();

  const { data } = useFetchUser({ enabled: true });
  // get all Industry list - Level list
  const { list: listIndustries, isFetching: fetchingIndustriesData } =
    useFetchList<IIndustryItemGroup>({
      ...resourceQuery.industry,
      customParams: {
        ...resourceQuery.industry.customParams,
        group: true,
      },
    });

  const { list: listLevel, isFetching: fetchingLevelData } = useFetchList<ILevelItem>(
    resourceQuery.level,
  );

  const { mutateAsync: createDomainKnowledge, isLoading: loadingCreate } =
    useMutate<ICreateKnowledge>(skillsQuery.createKnowledge);
  const { mutateAsync: updateDomainKnowledge, isLoading: loadingUpdate } =
    useMutate<IUpdateKnowledge>(skillsQuery.updateKnowledge);

  const industryValue = useWatch({ name: 'industry', control: control });

  useEffect(() => {
    setInitialForm({
      ...initialValue,
      industry: _get(initialValue, 'industry._id'),
      level: _get(initialValue, 'level._id'),
    });
    setValue('industry', _get(initialValue, 'industry._id'));
    setValue('level', _get(initialValue, 'level._id'));
    clearErrors();
  }, [initialValue]);

  useEffect(() => {
    reset(initialForm);
  }, [initialForm]);

  const clearUpdateForm = () => {
    setInitialForm({});
    reset({});
    resetInitialValue();
  };

  const handleRolesSubmit = (values: FormValues) => {
    if (_isEmpty(_get(initialForm, '_id'))) {
      createDomainKnowledge(
        {
          ...values,
          experienceYears: Number(values.experienceYears),
          user: data?._id || '',
          industry: values.industry,
        },
        {
          onSuccess: (data) => {
            Helper.toasts('', t('skills:createSuccess'), 'success');
            reset();
            refreshDataList();
          },
          onError: (err) => {
            Helper.toasts('', _get(err, 'message'), 'error');
          },
        },
      );
    } else {
      updateDomainKnowledge(
        {
          domainKnowledgeExperience: _get(initialForm, '_id'),
          ...values,
          experienceYears: Number(values.experienceYears),
          // user: data?._id || '',
        },
        {
          onSuccess: (data) => {
            Helper.toasts('', t('skills:updateSuccess'), 'success');
            clearUpdateForm();
            refreshDataList();
          },
          onError: (data) => {
            Helper.toasts('', _get(data, 'message'), 'error');
          },
        },
      );
    }
  };

  return (
    <form
      className="skills-form-container knowledge-form-container"
      onSubmit={handleSubmit(handleRolesSubmit)}
    >
      <Spin spinning={fetchingIndustriesData || fetchingLevelData}>
        <div className="roles-form-block">
          <div className="row-block">
            <SelectField
              name="industry"
              label={t('skills:industryField')}
              control={control}
              className="horizontal"
              required
              placeholder={t('placeholder:fieldSelect')}
              data={listIndustries}
              formLayout="vertical"
              colLabel={12}
              colWrapper={12}
              onSelect={(value) => {
                if (!_isEqual(value, industryValue)) {
                  setValue('level', '');
                }
              }}
              onClear={() => {
                setValue('level', '');
                setValue('industry', '', { shouldValidate: true });
                clearErrors('level');
              }}
              onChange={(val) => setValue('industry', val, { shouldValidate: true })}
              helperText={_get(dataHelpText, 'value.formHelpTextKnowledgePage.knowledge')}
            />
          </div>
          <div className="row-block">
            <SelectField
              name="level"
              control={control}
              className="w-130"
              label={t('skills:levelField')}
              required
              placeholder={t('placeholder:fieldSelect')}
              data={listLevel}
              colLabel={12}
              colWrapper={12}
              onClear={() => setValue('level', '', { shouldValidate: true })}
              disabled={!industryValue}
              formLayout="vertical"
              helperText={_get(dataHelpText, 'value.formHelpTextKnowledgePage.level')}
            />
          </div>
          <div className="row-block number-year">
            <TextInput
              name="experienceYears"
              control={control}
              label={t('skills:experienceYearsField')}
              required
              placeholder={t('placeholder:fieldExperienceYears')}
              suffix="年"
              suffixOutside={false}
              colLabel={12}
              colWrapper={12}
              formLayout="vertical"
            />
          </div>
          <div className="row-block full-row">
            <TextArea
              name="detail"
              control={control}
              label={t('skills:detailField')}
              placeholder={t('placeholder:fieldDetail')}
              maxLength={2000}
              isShowMaxLength={false}
              rows={8}
              showCount={false}
              colLabel={12}
              colWrapper={24}
              formLayout="vertical"
              helperText={_get(dataHelpText, 'value.formHelpTextKnowledgePage.detail')}
            />
          </div>

          <div className="submit-btn-wrapper">
            {!_isEmpty(_get(initialForm, '_id')) && (
              <Button
                htmlType="reset"
                className="btn-cancel"
                type="primary"
                onClick={() => clearUpdateForm()}
              >
                {t('button:cancelButton')}
              </Button>
            )}
            <Button
              htmlType="submit"
              className="second-color"
              type="primary"
              loading={loadingCreate || loadingUpdate}
              disabled={_isEmpty(initialForm) ? !isDirty || !isValid : !isValid}
            >
              {t('button:saveButton')}
            </Button>
          </div>
        </div>
      </Spin>
    </form>
  );
};

export default KnowledgeForm;
