/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Form } from 'antd';
import { InputProps } from 'antd/lib/input';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import HelperText from 'components/Form/HelperTextTooltip';

interface CheckBoxGroupProps<TFormValues extends FieldValues> extends InputProps {
  label?: string;
  noLabel?: boolean;
  className?: string;
  required?: boolean;
  name: Path<TFormValues>;
  options: any;
  defaultValue?: any;
  control: Control<TFormValues>;
  helperText?: string;
  isHidden?: boolean;
}

const CheckBoxGroup = <TFormValues extends FieldValues>({
  label,
  noLabel,
  className = '',
  required,
  name,
  options,
  control,
  helperText,
}: CheckBoxGroupProps<TFormValues>) => {
  const {
    field: { value = [], ref, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const isError = error;
  const handleOnchange = (checkedValues) => {
    onChange(checkedValues);
    onBlur();
  };

  const renderInput = () => {
    return (
      <Checkbox.Group
        options={options}
        value={value}
        onChange={handleOnchange}
        name={name}
        ref={ref}
      />
    );
  };

  return (
    <div className={`input-container text-field-container ${className}`}>
      {label && (
        <div className="label-wrapper">
          {required && !noLabel ? (
            <div className="required-mark">必須</div>
          ) : (
            <div className="not-required-mark">任意</div>
          )}
          <div className={'label'}>{label}</div>
          {helperText && <HelperText text={helperText} />}
        </div>
      )}
      <Form.Item
        validateStatus={isError ? 'error' : 'success'}
        help={
          <div className="helper-wrapper">
            <div className="error-text">
              {isError && (
                <>
                  <InfoCircleOutlined className="info-icon" type="info-circle" />
                  {error.message}
                </>
              )}
            </div>
          </div>
        }
      >
        {renderInput()}
      </Form.Item>
    </div>
  );
};

export default CheckBoxGroup;
