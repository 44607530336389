import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  loading: boolean;
  currentUser: unknown;
  isAuthorizing: 'idle' | 'loading' | 'success';
  isUnexpired: boolean;
}

const initialState = {
  isAuthorizing: 'idle',
  loading: false,
  currentUser: {},
  isUnexpired: true,
} as AuthState;

// For the error action, you can declare a general action to stop all loading
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest(state, action) {
      return { ...state, loading: true };
    },
    loginSuccess(state, action) {
      return { ...state, loading: false, isAuthorizing: 'success' };
    },
    loginError(state) {
      return { ...state, loading: false };
    },
    getCurrentUserRequest(state, action) {
      return {
        ...state,
        loading: true,
        isAuthorizing: action.payload.isAuthorizing ? 'loading' : state.isAuthorizing,
      };
    },
    getCurrentUserSuccess(state, action) {
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        isAuthorizing: 'success',
      };
    },
    getCurrentUserError(state) {
      return { ...state, loading: false, isAuthorizing: 'idle' };
    },
    logoutRequest(state) {
      return { ...state, loading: true };
    },
    logoutSuccess(state) {
      return { ...state, loading: false };
    },
    logoutError(state) {
      return { ...state, loading: false };
    },
    changePasswordRequest(state, action) {
      return { ...state, loading: true };
    },
    changePasswordSuccess(state, action) {
      return { ...state, loading: false };
    },
    preCheckEmailSignUpRequest(state, action) {
      return { ...state, loading: true };
    },
    preCheckEmailSignUpSuccess(state, action) {
      return { ...state, loading: false };
    },
    preCheckEmailSignUpError(state, action) {
      return { ...state, loading: false };
    },
    resetPasswordRequest(state, action) {
      return { ...state, loading: true };
    },
    resetPasswordSuccess(state, action) {
      return { ...state, loading: false };
    },
    resetPasswordError(state, action) {
      return { ...state, loading: false };
    },

    checkExpiredLinkRequest(state, action) {
      return { ...state, isUnexpired: false, loading: true };
    },
    checkExpiredLinkSuccess(state, action) {
      return { ...state, isUnexpired: true, loading: false };
    },
    checkExpiredLinkError(state, action) {
      return { ...state, isUnexpired: false, loading: false };
    },
    updateNewPasswordRequest(state, action) {
      return { ...state, loading: true };
    },
    updateNewPasswordSuccess(state, action) {
      return { ...state, loading: false };
    },
    updateNewPasswordError(state, action) {
      return { ...state, loading: false };
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  getCurrentUserRequest,
  loginError,
  getCurrentUserSuccess,
  logoutRequest,
  logoutSuccess,
  logoutError,
  getCurrentUserError,
  changePasswordRequest,
  changePasswordSuccess,
  preCheckEmailSignUpRequest,
  preCheckEmailSignUpSuccess,
  preCheckEmailSignUpError,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordError,
  checkExpiredLinkRequest,
  checkExpiredLinkSuccess,
  checkExpiredLinkError,
  updateNewPasswordRequest,
  updateNewPasswordSuccess,
  updateNewPasswordError,
} = authSlice.actions;

export default authSlice.reducer;
