import { createSlice } from '@reduxjs/toolkit';
import { NotificationItem } from 'admin-console-library';

export interface NotificationState {
  // PLOP: Add state interface below -- DO NOT ERASE
  loading: boolean;
  list: unknown[];
  total: number;
  page: number;
  isShowDot: boolean;
}

const initialState: NotificationState = {
  // PLOP: Add initial state below -- DO NOT ERASE
  loading: false,
  list: [],
  total: 0,
  page: 0,
  isShowDot: true,
};

const notificationState = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // PLOP: Add reducers below -- DO NOT ERASE
    getNotificationListRequest(state, action) {
      return { ...state, loading: true };
    },
    getNotificationListSuccess(state, action) {
      return {
        ...state,
        loading: false,
        list: [...state.list, ...action.payload.list],
        total: action.payload.total,
      };
    },
    getNotificationListError(state) {
      return { ...state, loading: false };
    },
    resetNotificationList(state) {
      return { ...state, list: [], total: 0 };
    },
    markReadAllRequest(state) {
      return { ...state, loading: true };
    },
    markReadAllSuccess(state, action) {
      const list = state.list.map((item: NotificationItem) => {
        return { ...item, unRead: false };
      });
      return {
        ...state,
        loading: false,
        list,
        isShowDot: false,
      };
    },
    markReadAllError(state) {
      return { ...state, loading: false };
    },
    markReadItemRequest(state, action) {
      return { ...state, loading: true };
    },
    markReadItemSuccess(state, action) {
      const list = state.list.map((item: NotificationItem) => {
        if (item.id === action.payload.id) {
          return { ...item, unRead: false };
        }
        return item;
      });
      const isShowDot = list.some((item: NotificationItem) => item.unRead);
      return {
        ...state,
        loading: false,
        list,
        isShowDot,
      };
    },
    markReadItemError(state) {
      return { ...state, loading: false };
    },
  },
});

export const {
  // PLOP: Add actions export below -- DO NOT ERASE
  getNotificationListRequest,
  getNotificationListSuccess,
  getNotificationListError,
  resetNotificationList,
  markReadAllRequest,
  markReadAllSuccess,
  markReadAllError,
  markReadItemRequest,
  markReadItemSuccess,
  markReadItemError,
} = notificationState.actions;

export default notificationState.reducer;
