import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { api } from 'utils/api';

interface Options<T>
  extends Omit<UseQueryOptions<unknown, unknown, T, string[]>, 'queryFn' | 'queryKey'> {
  queryKey: string[];
  apiUrl: string;
}

const useFetchDetail = <T>(options: Options<T>) => {
  const { queryKey, apiUrl, ...otherOptions } = options;

  const fetchData = async (): Promise<T> => {
    const { data: result } = await api.get(apiUrl);
    return result;
  };

  return useQuery(queryKey, fetchData, {
    keepPreviousData: true,
    ...otherOptions,
  });
};

export default useFetchDetail;
