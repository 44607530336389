import { useEffect, useState, useCallback } from 'react';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import TechnologiesForm from './form';
import TechnologiesData from './dataList';
import '../style.less';

interface PropsHeader {
  initialData:
    | Array<{
        _id?: string;
        usedTechnologyCategory: string;
        usedTechnologyName?: string;
        otherUsedTechnologyName?: string;
        usedTechnologyLevel: string;
      }>
    | []
    | any;
  data:
    | Array<{
        _id?: string;
        usedTechnologyCategory: string;
        usedTechnologyName?: string;
        otherUsedTechnologyName?: string;
        usedTechnologyLevel: string;
      }>
    | [];
  handleSetTechnologies: (data: any) => void;
}
const TechnologiesBox: React.FC<PropsHeader> = ({ data, handleSetTechnologies, initialData }) => {
  const [refreshForm, handleRefreshForm] = useState(false);

  useEffect(() => {
    if (initialData.length > 0 && data.length === 0) {
      handleSetTechnologies(initialData);
    }
    handleRefreshForm(!refreshForm);
  }, [initialData]);

  const handleDelete = (id: string) => {
    const array = [...data]; // make a separate copy of the array
    array.splice(Number(id), 1);
    handleSetTechnologies(array);
  };

  const handleAddItem = (values: any) => {
    handleSetTechnologies([...data, values]);
  };

  return (
    <div className="technologies-box-wrapper">
      <TechnologiesForm refreshForm={refreshForm} addItemToDataList={handleAddItem} />
      {data.length > 0 && <TechnologiesData data={data} handleDelete={handleDelete} />}
    </div>
  );
};

export default TechnologiesBox;
