import { Modal } from 'antd';
import _get from 'lodash/get';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { resetDataFormModal, setFormModal } from 'providers/GeneralProvider/slice';
import { useAppDispatch, useAppSelector } from 'store';
import './style.less';

type FormModalProps = {
  keyModal: string;
  children?: React.ReactElement;
  width?: number;
  title: string;
  className?: string;
};

const FormModal: React.FC<FormModalProps> = ({
  keyModal,
  children,
  width = 658,
  title,
  className = '',
}) => {
  const dispatch = useAppDispatch();
  const modalData = useAppSelector((state) => _get(state, `general[${keyModal}]`, {}));
  const isOpen = _get(modalData, 'isOpen', false);

  const handleCancel = () => {
    // console.log('handleCancel: ', modalData);
    dispatch(
      setFormModal({
        key: keyModal,
        isOpen: false,
        data: {},
      }),
    );
  };

  return (
    <Modal
      className={`form-modal ${className}`}
      title={title}
      open={isOpen}
      width={width}
      afterClose={() => dispatch(resetDataFormModal({ key: keyModal }))} // Remove popup data when popup is close
      onCancel={handleCancel}
      maskClosable={false}
      centered
      zIndex={1001}
      // style={{ top: 170 }}
      closeIcon={<AddCircleRoundedIcon />}
      footer={false}
      destroyOnClose={true}
    >
      {children}
    </Modal>
  );
};

export default FormModal;
