import { message as MessageComponent } from 'antd';
import _forOwn from 'lodash/forOwn';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { INSTALLATION_ID } from './constants';

const Helper = {
  generateUUID: (): string => {
    const hexOctet = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    return `${
      hexOctet + hexOctet
    }-${hexOctet}-${hexOctet}-${hexOctet}-${hexOctet}${hexOctet}${hexOctet}`;
  },
  getAuthToken: (): string | null => localStorage.getItem('op_token'),
  storeAuthToken: (token): void => localStorage.setItem('op_token', token),
  removeAuthToken: (): void => localStorage.removeItem('op_token'),
  setChangePassowrd: (): void => localStorage.setItem('change_password', 'change_password'),
  getChangePassowrd: (): string | null => localStorage.getItem('change_password'),
  removeChangePassowrd: (): void => localStorage.removeItem('change_password'),
  getInstallationId: (): string => {
    let installationId;
    const storedInstallationId = Helper.getDataStored(INSTALLATION_ID);
    if (storedInstallationId) {
      installationId = storedInstallationId;
    } else {
      installationId = Helper.generateUUID();
      Helper.storeData(INSTALLATION_ID, installationId);
    }
    return installationId;
  },

  toasts: (description: string, message: string, type = 'success'): void => {
    if (type === 'success') {
      MessageComponent.success({
        content: message,
        className: 'message-cutom success',
      });
    } else if (type === 'error') {
      MessageComponent.error({
        content: message,
        className: 'message-cutom error',
      });
    }
  },
  arrayToString: (array = []): string => array.map((item) => item).join(', '),
  getDataStored: (value: string): string | null => localStorage.getItem(value),
  storeData: (key: string, value: string): void => {
    localStorage.setItem(key, value);
  },
  getDownLoadFileCSV: (
    csvContent: number | boolean | BlobPart,
    csvFileName = 'dataList.csv',
  ): void => {
    const exportedFilename = csvFileName;
    const BOM = '\uFEFF';
    csvContent = BOM + csvContent;
    const blob = new Blob([csvContent], {
      type: `text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(csvContent)}`,
    });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  formatCurrencyJPYName: (value: number | bigint): string => {
    const currencyJPYName = new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
      currencyDisplay: 'name',
    });
    return currencyJPYName.format(value);
  },
  getFirstAndLastDayOfWeek: (date: string | number | Date): Record<string, number> => {
    const searchTime = new Date(date);
    // First day is the day of the month - the day of the week
    const firstDay = searchTime.getDate() - searchTime.getDay();
    const lastDay = firstDay + 6;
    return { firstDay, lastDay };
  },
  getFirstAndLastDayOfMonth: (date: Date): Record<string, Date> => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1); // get first day of the month
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0); // get last day of the search month
    return { firstDay, lastDay };
  },
  getThreeDayBeforeCurrentDay: (
    date: { getFullYear: () => number; getMonth: () => number; getDate: () => number },
    index: number,
  ): Record<string, number> => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1); // get first day of the month
    const lastDayOfPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);
    const firstDayOfMonth = firstDay.getDate();
    const datePast = date.getDate() - (3 - index); // get 3 days before current day
    const dateDisplayed =
      datePast < firstDayOfMonth ? lastDayOfPrevMonth.getDate() + datePast : datePast;
    return { datePast, dateDisplayed };
  },
  getThreeDayAfterCurrentDay: (
    date: { getFullYear: () => number; getMonth: () => number; getDate: () => number },
    index: number,
  ): Record<string, number> => {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0); // get last day of the search month
    const lastDayOfMonth = lastDay.getDate();
    const dateFuture = date.getDate() + (1 + index); // get 3 days after current day
    const dateDisplayed = dateFuture > lastDayOfMonth ? dateFuture - lastDayOfMonth : dateFuture;
    return { dateFuture, dateDisplayed };
  },
  getPrevCyclePayout: (
    searchTime: string,
    payoutCycleList: Record<string, string>[],
  ): Record<string, string> => {
    const CurrentDateNumber = (element) => element.value === searchTime;
    const index = payoutCycleList.findIndex(CurrentDateNumber);
    const prevIndex = index === 0 ? payoutCycleList.length - 1 : index - 1;
    const prevCycle = payoutCycleList[prevIndex].value;
    return { prevCycle };
  },
  getNextCyclePayout: (
    searchTime: string,
    payoutCycleList: Record<string, string>[],
  ): Record<string, string> => {
    const CurrentDateNumber = (element) => element.value === searchTime;
    const index = payoutCycleList.findIndex(CurrentDateNumber);
    const nextIndex = index === payoutCycleList.length - 1 ? 0 : index + 1;
    const nextCycle = payoutCycleList[nextIndex].value;
    return { nextCycle };
  },
  getUrl(url: string, apiUrl: string): string {
    return (url.startsWith('http') ? '' : apiUrl) + url;
  },
  objectToQueryString: (obj: Record<string, string>): string => {
    const results: Array<string> = [];
    _forOwn(obj, (value, key) => {
      if (Array.isArray(value)) {
        _forOwn(value, (value) => {
          results.push(`${key}=${value}`);
        });
      } else {
        results.push(`${key}=${value}`);
      }
    });
    return results.join('&');
  },
  convertToTechnologiesArray(arrayDataList): any {
    const arrResults: any[] = [];
    let objTemp = {
      usedTechnologyCategory: '',
      usedTechnologyLevel: '',
    };
    arrayDataList.map((item) => {
      objTemp = {
        usedTechnologyCategory: _get(item, 'usedTechnologyCategory._id'),
        usedTechnologyLevel: _get(item, 'usedTechnologyLevel._id'),
      };
      if (
        _get(item, 'usedTechnologyName._id') === 'other' ||
        _isEmpty(_get(item, 'usedTechnologyName._id'))
      ) {
        objTemp = Object.assign(objTemp, {
          otherUsedTechnologyName: _get(item, 'otherUsedTechnologyName'),
        });
      } else {
        objTemp = Object.assign(objTemp, {
          usedTechnologyName: _get(item, 'usedTechnologyName._id'),
        });
      }
      arrResults.push(objTemp);
    });
    return arrResults;
  },
  arrayObjToArrayString(list): any {
    const arrResults: any[] = [];
    list.map((item) => arrResults.push(item._id));
    return arrResults;
  },
};

export default Helper;
