/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { Row, Button, Spin, Space } from 'antd';
import _get from 'lodash/get';
import { useHistory, useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import HeaderComponent from 'components/Header';
import GreenBox from 'components/GreenBox';
import { useMutate } from 'hooks';
import authQuery from 'models/auth/query';
import { ICheckLinkingTokenPayload } from 'models/auth/interface';
import queryClient from 'utils/queryClient';

import Helper from 'utils/helpers';

import './styles.less';
import useFetchList from 'hooks/useList';
import { ISettingListData } from 'models/setting/interface';
import settingQuery from 'models/setting/query';

const LinkingEngineerRegisterPage = (): JSX.Element => {
  const { handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { push } = useHistory();

  const { search } = useLocation();

  const { list: settingList } = useFetchList<ISettingListData>(settingQuery.settingList);

  const termsOfUseLink =
    settingList.find((setting) => setting.name === 'termsOfUseLink')?.value || '';

  const privacyPolicyLink =
    settingList.find((setting) => setting.name === 'privacyPolicyLink')?.value || '';

  const dataPolicyLink =
    settingList.find((setting) => setting.name === 'dataPolicyLink')?.value || '';

  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token') as string;
  // state
  const [dataUser, setDataUser] = useState({});
  // APIs
  const { mutateAsync: checkLinkedToken, isLoading: loadingCheckToken } =
    useMutate<ICheckLinkingTokenPayload>(authQuery.checkLinkingToken);

  const { mutateAsync: logout } = useMutate(authQuery.logout);

  const handleLogout = async (data) => {
    setTimeout(() => {
      Helper.removeAuthToken();
      Helper.removeChangePassowrd();
    });
    logout(null);
    await queryClient.invalidateQueries(['currentUser'], {
      refetchType: 'none',
    });
    setTimeout(() => queryClient.resetQueries(['currentUser']));
  };

  // Check valid token confirm linking engineer
  const checkExpiredToken = () => {
    checkLinkedToken(
      { token: token },
      {
        onSuccess: (data) => {
          setDataUser(data);
          // if user logging with another account, force logout
          if (Helper.getAuthToken()) {
            handleLogout(data);
          }
        },
        onError: () => {
          push('/confirm-linking-url-expired');
        },
      },
    );
  };
  useEffect(() => checkExpiredToken(), []);

  // confirm to register Experport account
  const handleRegisterAccount = () => {
    push('/register', { ...dataUser, token });
  };
  // render spin loading if user login => logout
  if (Helper.getAuthToken() || loadingCheckToken) {
    return (
      <div
        id="big-spin"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 10000,
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  return (
    <div>
      <HeaderComponent />
      <Spin spinning={loadingCheckToken}>
        <div className="linking-engineer-register-wrapper ">
          <GreenBox title="会員登録">
            <form onSubmit={handleSubmit(handleRegisterAccount)}>
              <div className="email-user">{_get(dataUser, 'email')}</div>
              <Row justify="center">
                <Button className="registration-btn second-color" htmlType="submit" type="primary">
                  会員登録へ進む
                </Button>
              </Row>
              <div className="registration-policy">
                利用を開始することで、
                <a href={termsOfUseLink} target="_blank" className="policy-link" rel="noreferrer">
                  利用規約
                </a>
                (
                <a href={dataPolicyLink} target="_blank" className="policy-link" rel="noreferrer">
                  データ取扱規則を含む
                </a>
                ) &nbsp; /&nbsp; &nbsp;
                <a
                  href={privacyPolicyLink}
                  target="_blank"
                  rel="noreferrer"
                  className="policy-link"
                >
                  プライバシーポリシー
                </a>
                に同意したとみなします
              </div>
            </form>
          </GreenBox>
        </div>
      </Spin>
    </div>
  );
};

export default LinkingEngineerRegisterPage;
