/* eslint-disable max-len */
import { Row, Button, Spin } from 'antd';
import { useHistory } from 'react-router';
import _get from 'lodash/get';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import type { InferType } from 'yup';

import HeaderComponent from 'components/Header';

import GreenBox from 'components/GreenBox';
import { useMutate } from 'hooks';

import './styles.less';
import { IResetPasswordRequestPayload } from 'models/auth/interface';
import authQuery from 'models/auth/query';
import Helper from 'utils/helpers';
import { TextInput } from 'components/Form';
import ForgotPasswordEmail from './validate';
type ForgotPasswordFormValues = InferType<typeof ForgotPasswordEmail>;

const ResetPasswordRequestPage = (): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<ForgotPasswordFormValues>({
    resolver: yupResolver(ForgotPasswordEmail),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { push } = useHistory();
  const { mutateAsync, isLoading } = useMutate<IResetPasswordRequestPayload>(
    authQuery.resetPasswordRequest,
  );

  if (Helper.getAuthToken()) {
    push('/dashboard');
  }

  const handleRetPassword = (values) => {
    mutateAsync(
      { email: values?.email },
      {
        onSuccess: () => {
          push({ pathname: '/sent-reset-password-request', state: { email: values?.email } });
        },
        onError: (err) => {
          Helper.toasts('', _get(err, 'message'), 'error');
        },
      },
    );
  };

  return (
    <div>
      <HeaderComponent hasLoginButton />
      <Spin spinning={isLoading}>
        <div className="reset-password-page-wrapper">
          <GreenBox title="パスワードの再設定">
            <form name="register-account" onSubmit={handleSubmit(handleRetPassword)}>
              <div className="email-input-wrapper">
                <Row justify="center">
                  <TextInput
                    name="email"
                    noLabel
                    control={control}
                    required
                    placeholder="メールアドレス"
                    style={{ width: 343 }}
                  />
                </Row>
              </div>
              <div className="description">
                ご登録いただいたメールアドレスを入力してください。 <br />
                再設定の案内メールを送信します。
              </div>
              <Row justify="center">
                <Button
                  className="reset-password-btn second-color"
                  htmlType="submit"
                  type="primary"
                  disabled={!isValid}
                >
                  送信する
                </Button>
              </Row>
            </form>
          </GreenBox>
        </div>
      </Spin>
    </div>
  );
};

export default ResetPasswordRequestPage;
