/* eslint-disable max-len */
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import _get from 'lodash/get';
import HeaderComponent from 'components/Header';
import GreenBox from 'components/GreenBox';
import './styles.less';
import Helper from 'utils/helpers';

const VerifyEmailSuccessPage = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();

  if (Helper.getAuthToken()) {
    history.push('/dashboard');
  }

  return (
    <div>
      <HeaderComponent>
        <Link to={'/pre-check-email-sign-up'}>
          <Button type="primary" size="small">
            会員登録
          </Button>
        </Link>
      </HeaderComponent>
      <div className="verify-wrapper">
        <GreenBox title="認証メール送信完了">
          <div className="txt-email">{_get(location, 'state.params')}</div>
          <div>
            ご登録いただいたメールアドレスに認証メールを送信致しました。
            <br />
            メール内のURLをクリックして認証を行い、会員登録を完了させてください。
          </div>
          <br />
          <div>
            ※メールが届かない場合は、メールアドレスをご確認いただいた上で、再度登録をお願いします。
          </div>
          <br />
        </GreenBox>
      </div>
    </div>
  );
};

export default VerifyEmailSuccessPage;
