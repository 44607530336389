/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const skillsQuery = {
  workEnvironment: {
    apiUrl: '/v1/work-enviroment',
    queryKey: ['public', 'workEnvironment'],
    staleTime: Infinity,
  },
  updateWorkEnvironment: {
    apiUrl: '/v1/work-enviroment',
    method: 'put',
  },
  technologiesList: {
    apiUrl: '/v1/work-enviroment/technologies',
    queryKey: ['public', 'technologiesList'],
    customParams: {
      perPage: 100,
    },
    staleTime: Infinity,
  },
  createTechnologies: {
    apiUrl: '/v1/work-enviroment/technology',
  },
  updateTechnologies: {
    apiUrl: ({ technology }: { technology: string }) =>
      `/v1/work-enviroment/technology/${technology}`,
    method: 'put',
  },
  deleteTechnologies: {
    apiUrl: ({ technology }: { technology: string }) =>
      `/v1/work-enviroment/technology/${technology}`,
    method: 'delete',
    mode: 'cors',
  },
};

export default skillsQuery;
