/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Divider, Form, Space, Typography } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import { Fragment } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

interface CheckBoxGroupProps<TFormValues extends FieldValues> extends CheckboxProps {
  label?: string;
  noLabel?: boolean;
  className?: string;
  required?: boolean;
  name: Path<TFormValues>;
  options: any;
  defaultValue?: any;
  control: Control<TFormValues>;
  helperText?: string;
  isHidden?: boolean;
  colLabel?: number;
  colWrapper?: number;
  formLayout?: 'horizontal' | 'vertical';
  isCustomDisplayCheckbox?: boolean;
}

const CheckBoxGroup = <TFormValues extends FieldValues>({
  label,
  noLabel,
  className = '',
  required,
  name,
  options,
  control,
  helperText,
  colLabel,
  colWrapper,
  formLayout = 'horizontal',
  isCustomDisplayCheckbox,
  ...props
}: CheckBoxGroupProps<TFormValues>) => {
  const {
    field: { value = [], ref, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const handleOnchange = (checkedValues) => {
    onChange(checkedValues);
    onBlur();
  };

  const formItemLayout = colLabel
    ? {
        labelCol: { span: colLabel },
        wrapperCol: { span: colWrapper || 24 - colLabel },
      }
    : {};
  let convertedOptions = options;
  if (isCustomDisplayCheckbox) {
    convertedOptions = convertOptionsToRenderEachLine(options);
  }
  return (
    <Form.Item
      className={`checkbox-button ${formLayout}`}
      {...formItemLayout}
      label={
        label ? (
          <Space>
            {label}
            {required ? (
              <Typography.Text className="required-mark">必須</Typography.Text>
            ) : (
              <Typography.Text className="not-required-mark">任意</Typography.Text>
            )}
          </Space>
        ) : null
      }
      colon={false}
      validateStatus={error ? 'error' : 'success'}
      help={
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <div>
            {error && (
              <div className="error-text">
                <InfoCircleOutlined />
                {error.message}
              </div>
            )}
          </div>
        </Space>
      }
    >
      <Checkbox.Group value={value} onChange={handleOnchange} name={name} ref={ref}>
        {isCustomDisplayCheckbox
          ? convertedOptions.map((group, index) => (
              <Fragment key={index}>
                {group.map((item) => (
                  <Checkbox key={item.value || item._id} value={item.value || item._id}>
                    {item.label || item.nameJa || item.nameEn || item.name}
                  </Checkbox>
                ))}
                <Divider style={{ margin: 0, border: 'none', padding: '0' }} />
              </Fragment>
            ))
          : convertedOptions.map((item) => (
              <Checkbox key={item.value || item._id} value={item.value || item._id}>
                {item.label || item.nameJa || item.nameEn || item.name}
              </Checkbox>
            ))}
      </Checkbox.Group>
    </Form.Item>
  );
};

export default CheckBoxGroup;

function convertOptionsToRenderEachLine(data: any[]) {
  const options: any[] = [];
  let group: any[] = [];
  const displayCondition = [3, 4, 2, 4];
  let count = 0;

  data.forEach((item) => {
    group.push(item);
    if (displayCondition[count] === group.length) {
      options.push(group);
      group = [];
      count++;
    }
  });

  if (group.length > 0) {
    options.push(group);
  }

  return options;
}
