/* eslint-disable prettier/prettier */
import { useTranslation } from 'react-i18next';
import { Layout as LayoutPage, NotificationItem, NotificationProps } from 'admin-console-library';
import _set from 'lodash/set';
import { Link, useHistory } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import queryClient from 'utils/queryClient';
import i18n from 'i18n';

import { useAppDispatch, useAppSelector } from 'store';
import { changePasswordRequest } from 'providers/AuthProvider/slice';
import { setConfirmModal } from 'providers/GeneralProvider/slice';
import { AUTH0_CLIENT_ID, AUTH0_CONNECTION } from 'utils/config';
import Helper from 'utils/helpers';
import { indexRoutes, Route } from 'routes';
import { changeLanguage } from 'providers/LangProvider/slice';
import {
  getNotificationListRequest,
  markReadAllRequest,
  markReadItemRequest,
  resetNotificationList,
} from 'providers/NotificationProvider/slice';
// hooks
import { useFetchUser, useFetchListNotChangeSearchParam, useMutate } from 'hooks';
import authQuery from 'models/auth/query';
import HeaderUser from 'components/HeaderUser';
import ecLogo from 'assets/images/ec-logo.svg';

const LayoutContainer = ({
  children,
  path,
}: {
  children: JSX.Element;
  path: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('alert');
  const history = useHistory();
  const { location } = history;

  // APIs
  const { data } = useFetchUser({ enabled: true });
  const { mutateAsync: logout } = useMutate(authQuery.logout);

  // Notification -----------------------------

  const { list, params, total, loading, loadList } = useFetchListNotChangeSearchParam(
    getNotificationListRequest,
    {
      providerName: 'notification',
    },
  );
  const isShowDot = useAppSelector((state) => state.notification.isShowDot);

  const { page } = params;

  const loadDataNotification = (isOpen: boolean) => {
    if (isOpen) {
      dispatch(resetNotificationList());
      loadList({ page: 1 });
    }
  };

  const loadMoreNotification = () => {
    loadList({ page: page + 1 });
  };

  const markReadAll = () => {
    dispatch(markReadAllRequest());
  };

  const handleClickItem = (data: NotificationItem) => {
    dispatch(markReadItemRequest(data));
  };

  const notiProps: NotificationProps = {
    items: list as NotificationItem[],
    isLoading: loading,
    totalItems: total,
    height: 300,
    isShowDot: isShowDot,
    titleText: t('notification:notificationTitleText'),
    markReadText: t('notification:notificationMarkReadText'),
    loadingText: t('notification:notificationLoadingText'),
    loadedText: t('notification:notificationLoadedText'),
    noDataText: t('notification:notificationNoDataText'),
    handleLoadMore: loadMoreNotification,
    handleOpenNoti: loadDataNotification,
    handleClickMarkRead: markReadAll,
    handleClickItem: handleClickItem,
    // notiIcon: <UserOutlined />,
  };

  // End Notification -----------------------

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const routes = [] as any;
  // add translation for menu
  indexRoutes.forEach((itemRoute) => {
    const itemRouteCustom = { ...itemRoute };
    if (itemRoute.name) {
      itemRouteCustom.name = t(itemRoute.name);
    }
    if (itemRoute.routes) {
      itemRoute.routes.forEach((itemSubRoute, index) => {
        if (itemSubRoute.name) {
          _set(itemRouteCustom, `routes.${index}.name`, t(itemSubRoute.name));
        }
      });
    }
    routes.push(itemRouteCustom);
  });

  const handleLogout = async () => {
    setTimeout(() => {
      Helper.removeAuthToken();
      Helper.removeChangePassowrd();
    });
    logout(null);
    await queryClient.invalidateQueries(['currentUser'], {
      refetchType: 'none',
    });
    setTimeout(() => queryClient.resetQueries(['currentUser']));
    history.push('/');
  };

  const handleChangePassword = () => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          title: t('changePasswordModal:title'),
          content: t('changePasswordModal:content'),
          onOk: () => {
            dispatch(
              changePasswordRequest({
                client_id: AUTH0_CLIENT_ID,
                // email: user?.email,
                connection: AUTH0_CONNECTION,
              }),
            );
            Helper.setChangePassowrd();
          },
        },
      }),
    );
  };

  const handleChangeLang = (langValue) => {
    i18n.changeLanguage(langValue);
    dispatch(changeLanguage({ current: langValue }));
  };

  const handleClickLeftMenuItem = (pathValue) => {
    history.push(pathValue);
  };

  return (
    <LayoutPage
      layout="mix"
      menuHeaderRender={() => null}
      currentUser={{
        picture: '',
        name: '',
      }}
      pathname={location.pathname}
      route={{
        path: '/',
        routes: routes.filter((route: Route) => route.menu),
      }}
      lang=""
      langOptions={[]}
      logoImage="/icon.svg"
      logo={
        <Link to={'/dashboard'}>
          <img className="logo" src={ecLogo} alt="logo" />
        </Link>
      }
      handleChangeLang={handleChangeLang}
      handleClickLeftMenuItem={handleClickLeftMenuItem}
      isShowNotification={false}
      notiProps={notiProps}
      logoutText="ログアウト"
      title=""
      userRender={<HeaderUser dataUser={data} handleLogout={handleLogout} />}
      collapsed={false}
      siderWidth={235}
      // menuProps={{
      //   expandIcon: true, // not show collapse menu arrows
      // }}
      menu={{
        defaultOpenAll: path === '/skills', // submenu open when path is /skills
      }}
      // menuItemRender={(menuItemProps, defaultDom) => {
      //   if (menuItemProps.isUrl || menuItemProps.children) {
      //     return defaultDom;
      //   }
      //   if (menuItemProps.path) {
      //     if (!_isEmpty(menuItemProps.pro_layout_parentKeys)) {
      //       return (
      //         <Link to={menuItemProps.path.replace('/*', '')} target={menuItemProps.target}>
      //           {menuItemProps.icon && menuItemProps.icon}
      //           {defaultDom}
      //         </Link>
      //       );
      //     }
      //     return (
      //       <Link to={menuItemProps.path.replace('/*', '')} target={menuItemProps.target}>
      //         {defaultDom}
      //       </Link>
      //     );
      //   }
      //   return defaultDom;
      // }}
    >
      {children}
    </LayoutPage>
  );
};
export default LayoutContainer;
