import { useEffect, Suspense, useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import { ToastContainer } from 'react-toastify';
import en from 'antd/lib/locale/en_US';
import jp from 'antd/lib/locale/ja_JP';
import moment from 'moment';
import { ErrorBoundary } from 'admin-console-library';
import { QueryClientProvider } from '@tanstack/react-query';
import { indexRoutes, PublicRoutes, PrivateRoutes } from 'routes';
import ConfirmModal from 'components/ConfirmModal';
import DashboardPage from 'containers/Layout';
import DataProvider from 'containers/DataProvider';
import { useAppSelector } from 'store';
import 'styles/global.less';
import 'moment/locale/ja';
import { LAGUANGE_LIST } from 'utils/constants';
import queryClient from 'utils/queryClient';
import GoogleTagManager from 'components/GoogleTagManager';

LAGUANGE_LIST.forEach((item) => {
  moment.locale(item, {
    week: {
      dow: 8,
    },
  });
  moment.locale(item);
});

const intlMap = {
  en,
  jp,
};
const App = (): JSX.Element => {
  const lang = useAppSelector((state) => state.lang);
  const location = useLocation();
  const { search, pathname } = location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);

  const [qClient] = useState(queryClient);

  return (
    <div className="app-container">
      <GoogleTagManager />
      <ConfirmModal />
      <ConfigProvider locale={intlMap[lang.current]} autoInsertSpaceInButton={false}>
        <ErrorBoundary>
          <QueryClientProvider client={qClient}>
            <DataProvider />
            <Suspense
              fallback={
                <DashboardPage path="">
                  <Spin size="large" />
                </DashboardPage>
              }
            >
              <Switch>
                {indexRoutes.map((route) => {
                  if (!route.requireLogin) {
                    return (
                      <PublicRoutes
                        exact={route.exact}
                        path={route.path}
                        component={route.component}
                        key={route.path}
                      />
                    );
                  }
                  return (
                    <PrivateRoutes
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                      key={route.path}
                    />
                  );
                })}
              </Switch>
            </Suspense>
          </QueryClientProvider>
        </ErrorBoundary>
        <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
      </ConfigProvider>
    </div>
  );
};

export default App;
