import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
// import useGlobalState from 'hooks/useGlobalState';
import type { ICurrentUser } from 'models/auth/interface';
import authQuery from 'models/auth/query';
import { api } from 'utils/api';
import Helper from 'utils/helpers';

const useFetchUser = (
  options: Omit<UseQueryOptions<unknown, unknown, ICurrentUser, string[]>, 'queryFn' | 'queryKey'>,
) => {
  // const { setUpdateProfileModal } = useGlobalState();
  const { enabled, ...otherOptions } = options;
  const webCookie = Helper.getAuthToken();

  return useQuery(
    authQuery.getProfile.queryKey,
    async () => {
      const { data: result } = await api.get(authQuery.getProfile.apiUrl);
      return result;
    },
    {
      enabled: enabled && !!webCookie,
      staleTime: Infinity,
      onSuccess: (data) => {
        // console.log('success: ', data);
        // if (!data.setProfileAt) {
        //   setUpdateProfileModal(true);
        // }
      },
      ...otherOptions,
    },
  );
};

export default useFetchUser;
