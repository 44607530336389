/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Select, Form, Space, Typography } from 'antd';
import { useController, Control, Path, FieldValues } from 'react-hook-form';
import { SelectProps } from 'antd/lib/select';
import { InfoCircleOutlined } from '@ant-design/icons';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useState } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import HelperText from '../HelperTextTooltip';
import './style.less';
import '../style.less';

interface SelectFieldProps<TFormValues> extends SelectProps {
  label?: string;
  noLabel?: boolean;
  className?: string;
  placeholder?: string;
  required?: boolean;
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  data?:
    | { _id: string | number; name?: string; nameJa?: string; nameEn?: string; item?: string }[]
    | { label: string; options: { label: string; value: string }[] }[];
  helperText?: string;
  colLabel?: number;
  colWrapper?: number;
  loading?: boolean;
  formLayout?: 'horizontal' | 'vertical';
}

const SelectField = <TFormValues extends FieldValues>({
  label,
  noLabel,
  maxLength,
  data = [],
  placeholder = 'None',
  name,
  control,
  required,
  helperText,
  allowClear,
  colLabel,
  colWrapper,
  loading,
  formLayout = 'horizontal',
  ...props
}: SelectFieldProps<TFormValues>) => {
  const {
    field: { value, onBlur, ...otherField },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const [isFocus, setIsFocus] = useState(false);

  const formItemLayout = colLabel
    ? {
        labelCol: { span: colLabel },
        wrapperCol: { span: colWrapper || 24 - colLabel },
      }
    : {};

  const optionRenderItem = _isEmpty(_get(data[0], 'options'))
    ? data.map((option) => ({
        label: option.name || option.nameJa || option.item,
        value: option._id,
        title: null,
      }))
    : data;

  return (
    <Form.Item
      className={formLayout}
      {...formItemLayout}
      label={
        label ? (
          <Space>
            <Space size={6} style={{ padding: 0 }}>
              {helperText && (
                <>
                  <HelperText text={helperText} />
                </>
              )}
              <Typography.Text className={isFocus ? 'on-focus' : ''}>{label}</Typography.Text>
            </Space>
            {required ? (
              <Typography.Text className="required-mark">必須</Typography.Text>
            ) : (
              <Typography.Text className="not-required-mark">任意</Typography.Text>
            )}
          </Space>
        ) : null
      }
      colon={false}
      validateStatus={error ? 'error' : 'success'}
      help={
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <div>
            {error && (
              <div className="error-text">
                <InfoCircleOutlined />
                {error.message}
              </div>
            )}
          </div>
        </Space>
      }
    >
      <Select
        {...props}
        {...otherField}
        allowClear
        placeholder={placeholder}
        value={value === '' ? null : _get(value, '_id', value)}
        getPopupContainer={(trigger) => trigger.parentNode}
        onBlur={() => {
          onBlur();
          setIsFocus(false);
        }}
        onFocus={(event) => {
          (event.target as HTMLInputElement).value = '';
          setIsFocus(true);
        }}
        options={optionRenderItem}
        loading={loading}
        suffixIcon={<KeyboardArrowDownRoundedIcon />}
      />
    </Form.Item>
  );
};

export default SelectField;
