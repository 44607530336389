import { AxiosResponse } from 'axios';
import apiInstance from '../utils/api';

export default class BookingService extends apiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  getBookings = (params: any): Promise<AxiosResponse> => {
    return this.get('', { params });
  };
}
