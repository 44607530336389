import RolePage from 'containers/SkillsPage/RolePage';
import KnowledgePage from 'containers/SkillsPage/KnowledgePage';
import ExperiencePage from 'containers/SkillsPage/ExperiencePage';
import LanguagesPage from 'containers/SkillsPage/LanguagesPage';
import TechnicalSkillPage from 'containers/SkillsPage/TechnicalSkillPage';

const routes = [
  {
    path: '/skills/role',
    component: RolePage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/skills/domain-knowledge',
    component: KnowledgePage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/skills/technical-skills',
    component: TechnicalSkillPage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/skills/languages',
    component: LanguagesPage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/skills/work-experience',
    component: ExperiencePage,
    requireLogin: true,
    exact: true,
  },
];

export default routes;
