/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Checkbox, Form } from 'antd';
import { InputProps } from 'antd/lib/input';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

interface CheckBoxProps<TFormValues extends FieldValues> extends InputProps {
  label?: string;
  className?: string;
  name: Path<TFormValues>;
  control: Control<TFormValues>;
}

const CheckBox = <TFormValues extends FieldValues>({
  label,
  className = '',
  name,
  control,
}: CheckBoxProps<TFormValues>) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const isError = error;

  const renderInput = () => {
    return (
      <Checkbox onChange={onChange} className={className} name={name} checked={value}>
        {label}
      </Checkbox>
    );
  };

  return <Form.Item validateStatus={isError ? 'error' : 'success'}>{renderInput()}</Form.Item>;
};

export default CheckBox;
