/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Radio, Space, Typography } from 'antd';
import { RadioGroupProps } from 'antd/lib/radio';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useState } from 'react';

interface RadioBoxGroupProps<TFormValues extends FieldValues> extends RadioGroupProps {
  label?: string;
  noLabel?: boolean;
  className?: string;
  required?: boolean;
  name: Path<TFormValues>;
  options: any;
  defaultValue?: any;
  value?: any;
  control: Control<TFormValues>;
  helperText?: string;
  isHidden?: boolean;
  onChange?: any;
  colLabel?: number;
  colWrapper?: number;
  loading?: boolean;
  formLayout?: 'horizontal' | 'vertical';
}

const RadioGroup = <TFormValues extends FieldValues>({
  label,
  noLabel = false,
  className = '',
  required,
  name,
  options,
  control,
  helperText,
  defaultValue,
  value,
  onChange,
  colLabel,
  colWrapper,
  formLayout = 'horizontal',
  ...props
}: RadioBoxGroupProps<TFormValues>) => {
  const {
    field: { ref, onChange: updateField, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const isError = error;
  const handleOnchange = (checkedValues) => {
    {
      onChange && onChange(checkedValues);
    }
    updateField(checkedValues);
    onBlur();
  };

  const renderInput = () => {
    return (
      <Radio.Group
        options={options}
        value={value}
        onChange={handleOnchange}
        name={name}
        defaultValue={defaultValue}
        ref={ref}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      />
    );
  };

  const [isFocus, setIsFocus] = useState(false);
  const formItemLayout = colLabel
    ? {
        labelCol: { span: colLabel },
        wrapperCol: { span: colWrapper || 24 - colLabel },
      }
    : {};

  return (
    <Form.Item
      className={`radio-group-field-container ${formLayout} ${className}`}
      {...formItemLayout}
      label={
        label && !noLabel ? (
          <Space>
            <Typography.Text className={isFocus ? 'on-focus' : ''}>{label}</Typography.Text>
            {required ? (
              <Typography.Text className="required-mark">必須</Typography.Text>
            ) : (
              <Typography.Text className="not-required-mark">任意</Typography.Text>
            )}
          </Space>
        ) : null
      }
      colon={false}
      validateStatus={error ? 'error' : 'success'}
      help={
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <div>
            {error && (
              <div className="error-text">
                <InfoCircleOutlined />
                {error.message}
              </div>
            )}
          </div>
        </Space>
      }
    >
      {renderInput()}
    </Form.Item>
  );
};

export default RadioGroup;
