import { object, string } from 'yup';
import { Regex } from 'utils/constants';

const ResetPasswordSchema = object({
  password: string()
    .trim()
    .required('パスワードを入力してください。')
    .min(15, 'パスワードは15文字以上で入力してください。')
    .matches(Regex.PASSWORD, 'パスワードは英大文字小文字数字記号を混ぜてください。')
    .max(128, '入力文字数が上限に達しました。'),
});

export default ResetPasswordSchema;
