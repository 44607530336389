import { object, string } from 'yup';

const RolesSchema = object({
  roleCategory: string().required('役割を選択してください。'),
  roleProcess: string().required('工程を選択してください。'),
  level: string().required('レベルを選択してください。'),
  experienceYears: string()
    .trim()
    .test('test_greater_than_100', '', function (value) {
      if (!value) {
        return this.createError({ message: '経験年数を記入してください。' });
      }
      if (/[\uFF10-\uFF19]/.test(value) === true) {
        return this.createError({ message: '半角数字を入力して下さい。' });
      }
      if (isNaN(Number(value))) {
        return this.createError({ message: '数字を入力してください。' });
      }
      if (value && Number(value) > 100) {
        return this.createError({ message: '最大値 100.' });
      }
      if (value && Number(value) < 0) {
        return this.createError({ message: '最小値 0.' });
      }
      return true;
    }),
  detail: string(),
});

export default RolesSchema;
