import { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
import SelectField from 'components/Form/SelectNew';
import TextInput from 'components/Form/TextInputNew';
import MaskedInput from 'components/Form/MaskedInputNew';

// hooks
import useFetchList from 'hooks/useList';
import { useFetchUser, useMutate } from 'hooks';
import resourceQuery from 'models/resource/query';
import skillsQuery from 'models/skills/query';
import Helper from 'utils/helpers';
import './style.less';
import '../skills-style.less';
import { ICertInitValue, ICreateCert, IUpdateCert } from 'models/skills/interface';
import { ICertificationItem } from 'models/resource/interface';
import KnowledgeCertificationSchema from './validate';

type ICertifications = InferType<typeof KnowledgeCertificationSchema>;

const KnowledgeCertificationsForm: React.FC<{
  initialValue?: ICertInitValue;
  resetInitialValue: () => void;
}> = ({ initialValue, resetInitialValue }) => {
  const [initialForm, setInitialForm] = useState({});

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    setValue,
  } = useForm<ICertifications>({
    resolver: yupResolver(KnowledgeCertificationSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { t } = useTranslation();
  const { data } = useFetchUser({ enabled: true });

  const clearFormValues = () => {
    reset({});
    resetInitialValue();
    setValue('certificateItem', '');
    setValue('acquisitionAt', '');
    setValue('score', undefined);
    setInitialForm({});
  };
  // get domain certification list

  const { list: listLicense } = useFetchList<ICertificationItem>({
    ...resourceQuery.license,
    customParams: {
      ...resourceQuery.license.customParams,
      type: 'domain_knowledge',
    },
  });

  const { refetch: refetchList } = useFetchList<ICertifications>(
    skillsQuery.knowledgeCertificationList,
  );
  const { mutateAsync: createCertification, isLoading: loadingCreate } = useMutate<ICreateCert>(
    skillsQuery.createCertification,
  );
  const { mutateAsync: updateCertification, isLoading: loadingUpdate } = useMutate<IUpdateCert>(
    skillsQuery.updateCertification,
  );

  useEffect(() => {
    if (initialValue) {
      setInitialForm(initialValue);
      reset({
        ...initialValue,
        score: initialValue?.score ? initialValue?.score.toString() : '',
        acquisitionAt: initialValue.acquisitionAt
          ? moment(initialValue.acquisitionAt).format('YYYY/MM')
          : '',
        certificateItem: initialValue?.certificateItem?._id || '',
      });
    }
  }, [initialValue]);

  const handleSubmitCertification = (values) => {
    if (_isEmpty(initialValue)) {
      createCertification(
        {
          user: data?._id || '',
          certificateItem: values.certificateItem,
          acquisitionAt: moment(values?.acquisitionAt, 'YYYY/MM').toISOString(),
          type: 'DOMAIN_KNOWLEDGE',
          score: values?.score ? Number(values?.score) : undefined,
        },
        {
          onSuccess: () => {
            Helper.toasts('', 'ユーザー情報の更新成功', 'success');
            refetchList();
            // Update this logic later
            resetInitialValue();
            setValue('certificateItem', '');
            setValue('acquisitionAt', '');
            setValue('score', undefined);
            reset();
          },
          onError: (err) => {
            Helper.toasts('', _get(err, 'message'), 'error');
          },
        },
      );
    } else {
      updateCertification(
        {
          certificationId: initialValue?._id ? initialValue?._id : '',
          certificateItem: values.certificateItem,
          acquisitionAt: moment(values?.acquisitionAt, 'YYYY/MM').toISOString(),
          type: 'DOMAIN_KNOWLEDGE',
          score: values?.score ? Number(values?.score) : undefined,
        },
        {
          onSuccess: () => {
            Helper.toasts('', 'ユーザー情報の更新成功', 'success');
            refetchList();
            clearFormValues();
          },
          onError: (err) => {
            Helper.toasts('', _get(err, 'message'), 'error');
          },
        },
      );
    }
  };

  return (
    <form
      className="skills-form-container certification-form-container"
      onSubmit={handleSubmit(handleSubmitCertification)}
    >
      <Spin spinning={loadingCreate || loadingUpdate}>
        <div className="certification-form-block">
          <div className="row-block">
            <SelectField
              name="certificateItem"
              label={t('certifications:licenseName')}
              control={control}
              className="horizontal"
              required
              placeholder={t('placeholder:fieldLicense')}
              colLabel={12}
              colWrapper={24}
              formLayout="vertical"
              onClear={() => {
                setValue('certificateItem', '', { shouldValidate: true });
              }}
              data={listLicense}
            />
          </div>
          <div className="row-block">
            <MaskedInput
              name="acquisitionAt"
              control={control}
              mask="9999/99"
              label={t('certifications:dateOfAcquisition')}
              placeholder="YYYY/MM"
              colLabel={12}
              colWrapper={12}
              formLayout="vertical"
            />
          </div>
          <div className="row-block">
            <TextInput
              name="score"
              control={control}
              label={t('certifications:score')}
              placeholder={t('placeholder:fieldScore')}
              className="horizontal"
              colLabel={12}
              colWrapper={12}
              formLayout="vertical"
            />
          </div>

          <div className="submit-btn-wrapper">
            {!_isEmpty(initialForm) && (
              <Button
                htmlType="reset"
                className="btn-cancel"
                type="primary"
                onClick={() => {
                  clearFormValues();
                }}
              >
                {t('button:cancelButton')}
              </Button>
            )}
            <Button
              htmlType="submit"
              className="second-color"
              type="primary"
              loading={loadingCreate || loadingUpdate}
              disabled={_isEmpty(initialForm) ? !isDirty || !isValid : !isValid}
            >
              {t('button:saveButton')}
            </Button>
          </div>
        </div>
      </Spin>
    </form>
  );
};

export default KnowledgeCertificationsForm;
