/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Input, Form } from 'antd';
import ReactMaskedInput from 'react-input-mask';
import { InfoCircleOutlined } from '@ant-design/icons';
import { InputProps } from 'antd/lib/input';
import _get from 'lodash/get';
import { useController, Path, Control, FieldValues } from 'react-hook-form';
import { useState } from 'react';
import './style.less';

interface MaskedInputProps<TFormValues extends FieldValues> extends InputProps {
  label?: string;
  className?: string;
  inputType?: 'password' | 'textarea' | 'search' | undefined;
  required?: boolean;
  placeholder?: string;
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  mask: string;
  noLabel?: boolean;
}

const MaskedInput = <TFormValues extends FieldValues>({
  label,
  noLabel,
  maxLength,
  className = 'mask-input',
  placeholder,
  name,
  control,
  mask,
  required,
  ...rest
}: MaskedInputProps<TFormValues>) => {
  const {
    field: { ref, onChange, value, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const isError = error;
  const [isFocus, setIsFocus] = useState(false);

  return (
    <div className={`input-container text-field-container ${className}`}>
      {label && (
        <div className="label-wrapper">
          {required && !noLabel ? (
            <div className="required-mark">必須</div>
          ) : (
            <div className="not-required-mark">任意</div>
          )}
          <div className={`${isFocus ? 'on-focus-input' : 'label'}`}>{label}</div>
        </div>
      )}
      {noLabel && <div className="no-label"> </div>}

      <Form.Item
        validateStatus={isError ? 'error' : 'success'}
        help={
          <div className="helper-wrapper">
            <div className="error-text">
              {isError && (
                <>
                  <InfoCircleOutlined className="info-icon" type="info-circle" />
                  {error.message}
                </>
              )}
            </div>
            {maxLength && (
              <div className="max-length-text">
                {_get(value, 'length', 0)}/{maxLength}
              </div>
            )}
          </div>
        }
      >
        <ReactMaskedInput name={name} mask={mask} value={value} onChange={onChange} onBlur={onBlur}>
          {(inputProps) => (
            <Input
              {...inputProps}
              placeholder={placeholder}
              maxLength={maxLength}
              ref={ref}
              onFocusCapture={() => setIsFocus(true)}
              onBlurCapture={() => setIsFocus(false)}
              {...rest}
            />
          )}
        </ReactMaskedInput>
      </Form.Item>
    </div>
  );
};

export default MaskedInput;
