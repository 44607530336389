import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { Tooltip, Space } from 'antd';
import './style.less';

interface PropsHelperText {
  text: string;
}

const HelperText: React.FC<PropsHelperText> = ({ text }) => {
  return (
    <Space wrap className="helper-pop-wrapper">
      <Tooltip
        title={text}
        placement="bottomLeft"
        showArrow={false}
        className="helper-pop"
        color={'rgba(54, 62, 80, 0.8)'}
        overlayClassName="tooltip-wrapper"
      >
        <HelpRoundedIcon />
      </Tooltip>
    </Space>
  );
};

export default HelperText;
