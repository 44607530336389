import { Space, Button, List, Typography, Col, Row } from 'antd';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

const { Text } = Typography;

interface PropsHeader {
  data: Array<{
    _id?: string;
    usedTechnologyCategory:
      | string
      | { _id: string; name?: string; nameJa?: string; nameEn?: string };
    usedTechnologyName?: string | { _id: string; name?: string; nameJa?: string; nameEn?: string };
    otherUsedTechnologyName?: string;
    usedTechnologyLevel: string | { _id: string; name?: string; nameJa?: string; nameEn?: string };
  }>;
  className?: string;
  handleDelete: (objectId: string | any) => void;
}

const DataList: React.FC<PropsHeader> = ({ data, className, handleDelete }) => {
  return (
    <List
      className={`skills-list-container skills-list ${className || ''}`}
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(
        item: {
          usedTechnologyCategory:
            | string
            | { _id: string; name?: string; nameJa?: string; nameEn?: string };
          usedTechnologyName:
            | string
            | { _id: string; name?: string; nameJa?: string; nameEn?: string };
          otherUsedTechnologyName?: string;
          usedTechnologyLevel:
            | string
            | { _id: string; name?: string; nameJa?: string; nameEn?: string };
        },
        index,
      ) => (
        <List.Item
          actions={[
            <Button
              className="btn-delete"
              shape="circle"
              icon={<CloseRoundedIcon />}
              onClick={() => handleDelete(index)}
            />,
          ]}
          key={_get(item, '_id')}
        >
          <Space className="content-item" direction="vertical">
            <Row justify="start">
              <Col span={8}>
                <Text className="category">
                  {_get(item, 'usedTechnologyCategory.name') === '開発言語' ||
                  _get(item, 'usedTechnologyCategory.name') === 'フレームワーク'
                    ? '開発言語\n・フレームワーク'
                    : _get(item, 'usedTechnologyCategory.nameJa') ||
                      _get(item, 'usedTechnologyCategory.name') ||
                      _get(item, 'usedTechnologyCategory')}
                </Text>
              </Col>
              <Col span={7}>
                <Text className="name">
                  {_get(item, 'usedTechnologyName._id') === 'other' ||
                  _isEmpty(_get(item, 'usedTechnologyName', ''))
                    ? _get(item, 'otherUsedTechnologyName')
                    : _get(item, 'usedTechnologyName.nameJa') ||
                      _get(item, 'usedTechnologyName.name') ||
                      _get(item, 'usedTechnologyName')}
                </Text>
              </Col>
              <Col span={4}>
                <Text className="level">
                  {_get(item, 'usedTechnologyLevel.nameJa') ||
                    _get(item, 'usedTechnologyLevel.name') ||
                    _get(item, 'usedTechnologyLevel')}
                </Text>
              </Col>
            </Row>
          </Space>
        </List.Item>
      )}
    />
  );
};

export default DataList;
