import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { api } from 'utils/api';

export interface IListResult<T> {
  data: T[];
  totalData: number;
  totalPage: number;
  currentPage: number;
  lastPage: number;
  perPage: number;
}

interface Options<T>
  extends Omit<
    UseQueryOptions<unknown, unknown, IListResult<T>, string[]>,
    'queryFn' | 'queryKey'
  > {
  customParams?: Record<string, unknown>;
  queryKey: string[];
  apiUrl: string;
  omitKeys?: string[];
}

const getSortString = (orderBy: string, order: string): string => {
  if (order === 'true') {
    return `${orderBy}.desc`;
  }
  return `${orderBy}.asc`;
};

const useFetchList = <T = unknown>(
  options: Options<T>,
): {
  list: T[];
  total: number;
  page: number;
  lastPage: number;
  perPage: number;
  isFetching: boolean;
  isLoading: boolean;
  refetch: () => Promise<unknown>;
} => {
  const { queryKey, apiUrl, customParams, omitKeys, ...otherOptions } = options;

  const params = {
    page: 1,
    perPage: 10,
    ...customParams, // Do not change the order. Please pass another param if you want to override the params
  };

  const formatParams = (_params: Record<string, unknown>) => {
    const formattedParams = { ..._params };
    if (formattedParams.orderBy && formattedParams.order) {
      formattedParams.sort = getSortString(
        formattedParams.orderBy as string,
        formattedParams.order as string,
      );
      delete formattedParams.order;
      delete formattedParams.orderBy;
    }
    if (omitKeys) {
      omitKeys.forEach((key) => {
        delete formattedParams[key];
      });
    }
    return formattedParams;
  };

  const formattedParams = formatParams(params);
  const { data, isFetching, refetch, isLoading } = useQuery(
    [...queryKey, JSON.stringify(formattedParams, Object.keys(formattedParams).sort())],
    async () => {
      const data = await api.get(apiUrl, {
        params: formattedParams,
      });
      return data;
    },
    {
      suspense: false,
      keepPreviousData: true,
      ...otherOptions,
    },
  );

  return {
    list: data?.data || [],
    total: data?.totalData || 0,
    page: data?.currentPage || 1,
    lastPage: data?.lastPage || 1,
    perPage: data?.perPage || 10,
    isLoading,
    isFetching,
    refetch,
  };
};
export default useFetchList;
