import { Typography } from 'antd';
import { useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import './skills-style.less';

const { Text } = Typography;

const TextHasReadMore: React.FC<{ text: string }> = ({ text }) => {
  const [showMore, setShowMore] = useState(false);

  const handleExpandDetail = () => {
    setShowMore(!showMore);
  };

  return !_isEmpty(text) ? (
    <Text
      className={`description ellipsises ${showMore ? 'expand' : ''}`}
      onClick={handleExpandDetail}
    >
      <Text>{text}</Text>
    </Text>
  ) : (
    <></>
  );
};

export default TextHasReadMore;
