import moment from 'moment';
import { object, string, array } from 'yup';

const FormSchema = object({
  projectName: string().trim().required('プロジェクト名・業務名を入力してください。'),
  endUser: string(),
  responsibilityCompany: string(),
  periodStart: string()
    .required('プロジェクト開始日を入力してください。')
    .test('periodStart', '正しい日付を入力してください。', (date) => {
      if (date) {
        return (
          moment(date, 'YYYY/MM', true).isValid() &&
          moment(date, 'YYYY/MM', true).year() <= moment().year() &&
          moment(date, 'YYYY/MM', true).year() >= 1000 &&
          moment(date, 'YYYY/MM', true).isBefore(moment(new Date(), 'YYYY/MM'))
        );
      }
      return true;
    }),
  periodEnd: string().test('periodEnd', '正しい日付を入力してください。', (date, ref) => {
    if (date) {
      return (
        moment(date, 'YYYY/MM', true).isValid() &&
        moment(date, 'YYYY/MM', true).isSameOrAfter(moment(ref.parent.periodStart, 'YYYY/MM'))
      );
    }
    return true;
  }),
  roles: array()
    .required('役割を一つ以上選択してください。')
    .min(1, '役割を一つ以上選択してください。'),
  inChargeProcesses: array()
    .required('担当工程を一つ以上選択してください。')
    .min(1, '担当工程を一つ以上選択してください。'),
  technologies: array(),
  projectOverview: string(),
});

export default FormSchema;
