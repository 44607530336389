import { Space, Button, List, Typography, Col, Row } from 'antd';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import _get from 'lodash/get';
import moment from 'moment';
import { IListIntroduction } from 'models/introductions/interface';
import TextHasReadMore from '../../skills/TextHasReadMore';
import '../../skills/skills-style.less';
import './style.less';

const { Text } = Typography;

interface PropsHeader {
  data: Array<IListIntroduction>;
  className?: string;
  initLoading?: boolean;
  handleEdit: (objectItem: IListIntroduction) => void;
  handleDelete: (objectId: string | any) => void;
}

const DataList: React.FC<PropsHeader> = ({
  data,
  className,
  initLoading,
  handleEdit,
  handleDelete,
}) => {
  return (
    <List
      className={`skills-list-container introduction-list ${className || ''}`}
      itemLayout="horizontal"
      dataSource={data?.slice(0, 20)}
      loading={initLoading}
      renderItem={(item: IListIntroduction) => (
        <List.Item
          actions={[
            <Button
              className="btn-delete"
              shape="circle"
              icon={<CloseRoundedIcon />}
              onClick={() => handleDelete(_get(item, '_id'))}
            />,
            <Button
              className="btn-edit"
              shape="circle"
              icon={<CreateRoundedIcon />}
              onClick={() => handleEdit(item)}
            />,
          ]}
          key={item._id}
        >
          <Space className="content-item" direction="vertical">
            <Row justify="start">
              <Col span={12}>
                <Text className="types memo">{_get(item, 'memo', '')}</Text>
              </Col>
              <Col span={8}>
                {item?.linkToAP ? (
                  <Text className="level linked-to-AP">Aperport連携に使用</Text>
                ) : null}
              </Col>
            </Row>
            <Row justify="start">
              <Text className="update-at">
                {moment(item.updatedAt).format('YYYY年MM月DD日 HH:mm更新')}
              </Text>
            </Row>
            <Row>
              <Col span={24}>
                <TextHasReadMore text={_get(item, 'selfIntroductionDetail', '')} />
              </Col>
            </Row>
          </Space>
        </List.Item>
      )}
    />
  );
};

export default DataList;
