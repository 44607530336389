import moment from 'moment';
import { Button, List, Typography, Col, Row } from 'antd';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import _get from 'lodash/get';
import { ICertInitValue } from 'models/skills/interface';
import './style.less';
import '../skills-style.less';

const { Text } = Typography;

interface ICertListProps {
  data: Array<ICertInitValue>;
  className?: string;
  initLoading?: boolean;
  handleEdit: (objectItem: ICertInitValue) => void;
  handleDelete: (objectId: string | any) => void;
}

const TechnicalCertificationsList: React.FC<ICertListProps> = ({
  data = [],
  className,
  initLoading,
  handleEdit,
  handleDelete,
}) => {
  return (
    <List
      className={`skills-list-container certifications-list ${className || ''}`}
      style={{ paddingTop: 80 }} // impact css in .technical-wrapper => style inline
      itemLayout="horizontal"
      dataSource={data.slice(0, 20) || []}
      loading={initLoading}
      renderItem={(item: ICertInitValue) => (
        <List.Item
          actions={[
            <Button
              className="btn-delete"
              shape="circle"
              icon={<CloseRoundedIcon />}
              onClick={() => handleDelete(_get(item, '_id'))}
            />,
            <Button
              className="btn-edit"
              shape="circle"
              icon={<CreateRoundedIcon />}
              onClick={() => handleEdit(item)}
            />,
          ]}
          key={item._id}
        >
          <Row justify="start">
            <Col span={12}>
              <Text className="license-name"> {_get(item, 'certificateItem.name')} </Text>
            </Col>
            <Col span={4}>
              <div className="date-of-acquisition">
                {item.acquisitionAt ? moment(item.acquisitionAt).format('YYYY年MM月') : ''}
              </div>
            </Col>
            <Col span={4}>
              <div className="score">
                {item.score}
                {item?.score ? '点' : ''}
              </div>
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );
};

export default TechnicalCertificationsList;
