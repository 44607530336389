import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { Button, Spin } from 'antd';
import { useForm, useWatch } from 'react-hook-form';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
import { useTranslation } from 'react-i18next';
import TextInput from 'components/Form/TextInputNew';
import SelectField from 'components/Form/SelectNew';

// hooks
import useFetchList from 'hooks/useList';
import { useFetchUser, useMutate, useFetchDetail } from 'hooks';
import resourceQuery from 'models/resource/query';
import authQuery from 'models/auth/query';
import settingQuery from 'models/setting/query';
import { IHelpTextData } from 'models/setting/interface';
import Helper from 'utils/helpers';
import {
  CONTRACT_TYPE,
  isNotFreelanceContract,
  isOutsourcingConTract,
  JOB_TITLE,
  notOutsourcingConTract,
  SOCIAL_INSURANCE_STATUS,
} from 'utils/constants';
import './style.less';

import {
  ICityItem,
  ICountryItem,
  ILineItem,
  IPrefectureItem,
  IStationItem,
} from 'models/resource/interface';
import { IUpdateProfilePayload } from 'models/auth/interface';
import MaskedInput from 'components/Form/MaskedInput';
import CheckBoxGroup from 'components/Form/CheckboxGroup';
import UpdateProfileSchema from './validate';

type UpdateProfileFormValues = InferType<typeof UpdateProfileSchema>;

const UpdateProfileForm: React.FC = () => {
  const { t } = useTranslation();
  const { data: currentUser, refetch } = useFetchUser({ enabled: true });
  // get all country list and prefecture list
  const { list: listCountry } = useFetchList<ICountryItem>(resourceQuery.country);
  const { list: listPrefecture, isFetching: fetchingData } = useFetchList<IPrefectureItem>(
    resourceQuery.prefecture,
  );
  const { mutateAsync: updateProfile, isLoading } = useMutate<IUpdateProfilePayload>(
    authQuery.updateProfile,
  );

  const { data: dataHelpText } = useFetchDetail<IHelpTextData>({
    queryKey: ['getHelpText'],
    apiUrl: settingQuery.getHelpText.apiUrl,
  });

  // initial values
  const initialValue = useMemo(() => {
    return {
      ..._pick(currentUser, [
        '_id',
        'affiliatedCompanyName',
        'city',
        'contractType',
        'companyMail',
        'fullName',
        'buildingName',
        'initial',
        'companyNameOriginal',
        'phonetic',
        'socialInsuranceStatus',
        'username',
      ]),
      jobTitle: currentUser?.jobTitle,
      employmentPeriodStartTime: currentUser?.employmentPeriodStartTime
        ? moment(currentUser?.employmentPeriodStartTime).format('YYYY/MM/DD')
        : undefined,
      employmentPeriodEndTime: currentUser?.employmentPeriodEndTime
        ? moment(currentUser?.employmentPeriodEndTime).format('YYYY/MM/DD')
        : undefined,
      trusteeBusinessPeriodStartTime: currentUser?.trusteeBusinessPeriodStartTime
        ? moment(currentUser?.trusteeBusinessPeriodStartTime).format('YYYY/MM/DD')
        : undefined,
      trusteeBusinessPeriodEndTime: currentUser?.trusteeBusinessPeriodEndTime
        ? moment(currentUser?.trusteeBusinessPeriodEndTime).format('YYYY/MM/DD')
        : undefined,
      birthday: currentUser?.birthday
        ? moment(currentUser?.birthday).format('YYYY/MM/DD')
        : undefined,
      // prefecture, city, lineStation, stationNearest are required, so use prefectureId
      prefecture: currentUser?.prefecture?._id ? currentUser?.prefecture?._id : '',
      city: currentUser?.prefecture?._id ? currentUser?.city?._id : '',
      lineStation: currentUser?.prefecture?._id ? currentUser?.lineStation?._id : '',
      stationNearest: currentUser?.prefecture?._id ? currentUser?.stationNearest?._id : '',
      countryCitizenship: currentUser?.countryCitizenship?._id
        ? currentUser?.countryCitizenship?._id
        : '',
      countryOrigin: currentUser?.countryOrigin?._id ? currentUser?.countryOrigin?._id : '',
    };
  }, [currentUser]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
    setValue,
    clearErrors,
    trigger,
  } = useForm<UpdateProfileFormValues>({
    resolver: yupResolver(UpdateProfileSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: initialValue,
  });

  useEffect(() => {
    trigger();
  }, []);

  useEffect(() => {
    reset(initialValue);
  }, [initialValue]);

  // form state
  const contractType = useWatch({ name: 'contractType', control: control });

  // get Cities list  -> prefecture
  const prefectureValue = useWatch({ name: 'prefecture', control: control });
  const prefecture = listPrefecture.find((p) => p._id === prefectureValue);
  const isoKey = prefecture?.iso;
  const { list: listCity, isFetching: loadingCity } = useFetchList<ICityItem>({
    ...resourceQuery.city,
    enabled: !!prefectureValue,
    apiUrl: 'v1/public/city/list',
    queryKey: ['public', 'city', prefectureValue],
    customParams: {
      search: prefectureValue,
      perPage: 500,
    },
  });
  // get Lines list -> prefecture
  const { list: listLine } = useFetchList<ILineItem>({
    ...resourceQuery.line,
    enabled: !!prefectureValue,
    apiUrl: `v1/public/station/lines/${isoKey}`,
    queryKey: ['public', 'lines', prefectureValue],
    customParams: {
      search: isoKey,
      perPage: 500,
    },
  });

  // get Station nearest list -> lines
  const lineValue = useWatch({ name: 'lineStation', control: control });
  const { list: listStation, isFetching: loadingStation } = useFetchList<IStationItem>({
    ...resourceQuery.station,
    enabled: !!lineValue,
    apiUrl: `v1/public/station/${lineValue}`,
    queryKey: ['public', 'station', lineValue],
  });
  const setValueTimeout = (name, value, shouldValidate = { shouldValidate: false }) => {
    setTimeout(() => setValue(name, value, shouldValidate), 1);
  };
  const resetValuePrefectureChanged = (value) => {
    setValueTimeout('prefecture', value, { shouldValidate: true });
    setValueTimeout('city', '');
    setValueTimeout('lineStation', '');
    setValueTimeout('stationNearest', '');
    clearErrors('city');
    clearErrors('lineStation');
    clearErrors('stationNearest');
  };
  useEffect(() => {
    if (contractType === 'FREELANCE') {
      setValueTimeout('employmentPeriodEndTime', '');
      clearErrors('employmentPeriodEndTime');
      clearErrors('buildingName');
      clearErrors('companyNameOriginal');
      clearErrors('affiliatedCompanyName');
    }
  }, [contractType]);

  const handleUpdateProfile = (values: UpdateProfileFormValues) => {
    const lineStation = listLine.find((l) => l._id === values?.lineStation);
    const stationNearest = listStation.find((l) => l._id === values?.stationNearest);
    updateProfile(
      {
        ...values,
        jobTitle: values.jobTitle,
        birthday: values?.birthday ? moment(values?.birthday).toISOString() : undefined,
        employmentPeriodStartTime: values?.employmentPeriodStartTime
          ? moment(values.employmentPeriodStartTime).toISOString()
          : undefined,
        employmentPeriodEndTime: values?.employmentPeriodEndTime
          ? moment(values?.employmentPeriodEndTime).toISOString()
          : undefined,
        trusteeBusinessPeriodStartTime: values?.trusteeBusinessPeriodStartTime
          ? moment(values.trusteeBusinessPeriodStartTime).toISOString()
          : undefined,
        trusteeBusinessPeriodEndTime: values?.trusteeBusinessPeriodEndTime
          ? moment(values?.trusteeBusinessPeriodEndTime).toISOString()
          : undefined,
        lineStation: _pick(lineStation, ['_id', 'name', 'nameKata', 'nameHira']) as ILineItem,
        stationNearest: _pick(stationNearest, [
          '_id',
          'name',
          'nameKata',
          'nameRoman',
        ]) as IStationItem,
      },
      {
        onSuccess: () => {
          Helper.toasts('', 'ユーザー情報の更新成功', 'success');
          refetch();
        },
        onError: (err) => {
          Helper.toasts('', _get(err, 'message'), 'error');
        },
      },
    );
  };

  if (!currentUser?.email) {
    return (
      <div
        id="big-spin"
        style={{
          position: 'fixed',
          top: '10%',
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          zIndex: 10000,
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <form className="update-profile-form-container" onSubmit={handleSubmit(handleUpdateProfile)}>
      <Spin spinning={isLoading || fetchingData}>
        <div className="update-profile-form-block">
          {/* User information */}
          <div className="row-block">
            <div className="user-info">ユーザー情報</div>
          </div>
          <div className="row-block pt-48">
            <TextInput
              name="fullName"
              label="氏名"
              control={control}
              required
              placeholder="例）鈴木　太郎"
              className="w-320"
              formLayout="vertical"
            />
            <TextInput
              name="phonetic"
              label="氏名（ヨミガナ）"
              control={control}
              placeholder="例）スズキ　タロウ"
              className="w-320"
              formLayout="vertical"
            />
          </div>
          <div className="pt-16">
            <TextInput
              name="username"
              label="ユーザー名"
              control={control}
              className="w-320"
              placeholder="例）Taro"
              helperText={_get(dataHelpText, 'value.formHelpTextProfilePage.username')}
              formLayout="vertical"
            />
          </div>
          <div className="pt-16">
            <TextInput
              name="initial"
              label="イニシャル"
              control={control}
              className="w-320"
              placeholder="例）Y.S"
              required
              formLayout="vertical"
              helperText={_get(dataHelpText, 'value.formHelpTextProfilePage.initial')}
            />
          </div>
          <div className="pt-16">
            <SelectField
              name="contractType"
              label="契約形態"
              control={control}
              formLayout="vertical"
              required
              placeholder="選択してください"
              data={CONTRACT_TYPE}
              onClear={() => setValueTimeout('contractType', '', { shouldValidate: true })}
              className="w-320"
              helperText={_get(dataHelpText, 'value.formHelpTextProfilePage.contractType')}
            />
          </div>
          {!isOutsourcingConTract.includes(contractType) && (
            <div className="pt-16">
              <TextInput
                name="affiliatedCompanyName"
                label="所属会社名"
                control={control}
                className="w-320"
                required={notOutsourcingConTract.includes(contractType)}
                placeholder="例）スフィアネット株式会社"
                formLayout="vertical"
                helperText={_get(dataHelpText, 'value.formHelpTextProfilePage.companyName')}
              />
            </div>
          )}
          {isOutsourcingConTract.includes(contractType) && (
            <div className="pt-16">
              <TextInput
                name="buildingName"
                label="屋号・法人名"
                control={control}
                className="w-320"
                placeholder="例）スフィアネット株式会社"
                formLayout="vertical"
              />
            </div>
          )}
          {isOutsourcingConTract.includes(contractType) && (
            <div className="pt-16">
              <TextInput
                name="companyNameOriginal"
                label="所属先（業務委託契約先）会社名"
                control={control}
                required={isOutsourcingConTract.includes(contractType)}
                placeholder="例）スフィアネット株式会社"
                formLayout="vertical"
                helperText={_get(dataHelpText, 'value.formHelpTextProfilePage.outsourcing')}
              />
            </div>
          )}

          <div className="pt-16">
            <TextInput
              name="companyMail"
              label="所属会社発行のメールアドレス"
              control={control}
              required
              className="w-320"
              placeholder="例）mail@spherenet.o.jp"
              formLayout="vertical"
              helperText={_get(dataHelpText, 'value.formHelpTextProfilePage.companyMailaddress')}
            />
          </div>

          {notOutsourcingConTract.includes(contractType) && (
            <div className="row-block-period-date pt-16">
              <div className="w-320">
                <MaskedInput
                  name="employmentPeriodStartTime"
                  mask="9999/99/99"
                  label="雇用期間"
                  required
                  placeholder="YYYY/MM/DD"
                  control={control}
                  style={{ width: 320 }}
                  className="mask-input"
                />
              </div>
              {isNotFreelanceContract.includes(contractType) && (
                <>
                  <div className="icon-between-fields"> ～ </div>
                  <div className="w-320">
                    <MaskedInput
                      name="employmentPeriodEndTime"
                      mask="9999/99/99"
                      noLabel={true}
                      required={isNotFreelanceContract.includes(contractType)}
                      placeholder="YYYY/MM/DD"
                      control={control}
                      style={{ width: 320 }}
                      className="mask-input"
                    />
                  </div>
                </>
              )}
            </div>
          )}

          {isOutsourcingConTract.includes(contractType) && (
            <div className="row-block-period-date pt-16">
              <div className="w-320">
                <MaskedInput
                  name="trusteeBusinessPeriodStartTime"
                  mask="9999/99/99"
                  label="業務受託期間"
                  required
                  placeholder="YYYY/MM/DD"
                  control={control}
                  style={{ width: 320 }}
                  className="mask-input"
                />
              </div>
              <div className="icon-between-fields"> ～ </div>
              <div className="w-320">
                <MaskedInput
                  name="trusteeBusinessPeriodEndTime"
                  mask="9999/99/99"
                  noLabel={true}
                  required={isNotFreelanceContract.includes(contractType)}
                  placeholder="YYYY/MM/DD"
                  control={control}
                  style={{ width: 320 }}
                  className="mask-input"
                />
              </div>
            </div>
          )}

          <div className="pt-16">
            <SelectField
              name="socialInsuranceStatus"
              label="社会保険加入状況"
              control={control}
              className="w-320"
              required
              placeholder="選択してください"
              data={SOCIAL_INSURANCE_STATUS}
              onClear={() => setValueTimeout('socialInsuranceStatus', '', { shouldValidate: true })}
              formLayout="vertical"
            />
          </div>

          <div className="pt-16">
            <CheckBoxGroup
              name="jobTitle"
              label="職種"
              control={control}
              required
              placeholder="選択してください"
              options={JOB_TITLE}
              defaultValue={initialValue?.jobTitle}
            />
          </div>

          <div className="row-block pt-16">
            <SelectField
              name="countryCitizenship"
              label="国籍"
              control={control}
              placeholder="選択してください"
              data={listCountry}
              onClear={() => {
                setValueTimeout('countryCitizenship', '');
              }}
              className="w-320"
              formLayout="vertical"
              colLabel={24}
              colWrapper={24}
            />
            <SelectField
              name="countryOrigin"
              label="出身国・地域"
              control={control}
              placeholder="選択してください"
              data={listCountry}
              onClear={() => {
                setValueTimeout('countryOrigin', '');
              }}
              className="w-320"
              formLayout="vertical"
              colLabel={24}
              colWrapper={24}
            />
          </div>

          <div className="row-block pt-16">
            <SelectField
              name="prefecture"
              label="住所"
              control={control}
              required
              onSelect={(value) => {
                if (!_isEqual(value, prefectureValue)) {
                  resetValuePrefectureChanged(value);
                }
              }}
              onClear={() => resetValuePrefectureChanged('')}
              placeholder="都道府県"
              data={listPrefecture}
              className="w-320"
              formLayout="vertical"
            />
            <SelectField
              name="city"
              noLabel={true}
              control={control}
              placeholder="市区町村"
              data={listCity}
              loading={loadingCity}
              disabled={!prefectureValue}
              // onClear={() => setTimeout(() => setValue('city', '', { shouldValidate: true }), 1)}
              onClear={() => setValueTimeout('city', '', { shouldValidate: true })}
              className="w-320"
              formLayout="vertical"
            />
          </div>
          <div className="row-block pt-16">
            <SelectField
              name="lineStation"
              label="最寄り駅"
              control={control}
              required
              placeholder="路線"
              data={listLine}
              onSelect={(value) => {
                if (!_isEqual(value, lineValue)) {
                  setValueTimeout('stationNearest', '');
                }
              }}
              onClear={() => {
                setValueTimeout('lineStation', '', { shouldValidate: true });
                setValueTimeout('stationNearest', '');
                clearErrors('stationNearest');
              }}
              disabled={!prefectureValue}
              className="w-320"
              formLayout="vertical"
            />
            <SelectField
              name="stationNearest"
              noLabel={true}
              control={control}
              required
              placeholder="駅名"
              data={listStation}
              loading={loadingStation}
              disabled={!prefectureValue || !lineValue}
              onClear={() => setValueTimeout('stationNearest', '', { shouldValidate: true })}
              className="w-320"
              formLayout="vertical"
            />
          </div>

          <div className="w-320 pt-16">
            <MaskedInput
              mask="9999/99/99"
              label="生年月日"
              name="birthday"
              placeholder="YYYY/MM/DD"
              control={control}
              className="mask-input"
            />
          </div>

          <div className="submit-btn-wrapper">
            <Button
              htmlType="submit"
              className="second-color"
              type="primary"
              loading={isLoading}
              disabled={!isValid}
            >
              変更
            </Button>
          </div>
        </div>
      </Spin>
    </form>
  );
};

export default UpdateProfileForm;
