import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { PageContainer } from '@ant-design/pro-layout';
import { Spin } from 'antd';

import { useMutate } from 'hooks';
import authQuery from 'models/auth/query';
import Helper from 'utils/helpers';
import { useAppDispatch } from 'store';
import { setConfirmModal } from 'providers/GeneralProvider/slice';
import queryClient from 'utils/queryClient';

const ConfirmChangeEmail: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token') as string;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // APIs
  const { mutateAsync: confirmChangeEmail } = useMutate<{ token: string }>(
    authQuery.confirmChangeEmail,
  );
  const { mutateAsync: logout } = useMutate(authQuery.logout);

  useEffect(() => {
    confirmChangeEmail(
      { token: token },
      {
        onSuccess: () => {
          dispatch(
            setConfirmModal({
              visible: true,
              data: {
                title: t('changeEmailModal:successChange'),
                content: t('changeEmailModal:contentSuccess'),
                className: 'hide-close-button',
                onOk: () => {
                  setTimeout(() => {
                    Helper.removeAuthToken();
                    Helper.removeChangePassowrd();
                  });
                  logout(null);
                  queryClient.invalidateQueries(['currentUser'], {
                    refetchType: 'none',
                  });
                  setTimeout(() => queryClient.resetQueries(['currentUser']));
                  history.push('/login');
                },
                onCancel: () => {
                  setTimeout(() => {
                    Helper.removeAuthToken();
                    Helper.removeChangePassowrd();
                  });
                  logout(null);
                  queryClient.invalidateQueries(['currentUser'], {
                    refetchType: 'none',
                  });
                  setTimeout(() => queryClient.resetQueries(['currentUser']));
                  history.push('/login');
                },
              },
            }),
          );
        },
        onError: (err) => {
          Helper.toasts('', _get(err, 'message'), 'error');
          setTimeout(() => history.push('/'), 1500);
        },
      },
    );
  }, []);

  return (
    <PageContainer>
      <Spin />
    </PageContainer>
  );
};

export default ConfirmChangeEmail;
