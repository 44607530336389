import { Layout, Button, Row, Col } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import './style.less';
import ecLogo from 'assets/images/ec-logo.svg';
import Helper from 'utils/helpers';
const { Header } = Layout;
interface PropsHeader {
  hasLoginButton?: boolean;
  hasRegisterButton?: boolean;
  className?: string;
  children?: any;
}

const HeaderComponent: React.FC<PropsHeader> = ({
  hasLoginButton,
  hasRegisterButton,
  className,
  children,
}) => {
  const token = Helper.getAuthToken();
  const { pathname } = useLocation();

  const renderHeaderUser = () => (
    <div className="site-button-wrapper">
      {hasLoginButton && (
        <Link to={'/login'}>
          <Button type="primary" className="second-color" size="small">
            ログイン
          </Button>
        </Link>
      )}
      {hasRegisterButton && (
        <Link to={'/pre-check-email-sign-up'}>
          <Button type="primary" size="small">
            会員登録
          </Button>
        </Link>
      )}
      {children && children}
    </div>
  );

  const onPress = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent | undefined>) => {
    e.preventDefault();
    const itemId = e.currentTarget.href.split('#')[1] as string;
    const target = window.document.getElementById(itemId);
    if (target) {
      const headerOffset = itemId === 'merit' ? 10 : 100;
      const elementPosition = target.getBoundingClientRect().top;
      const offsetPosition = elementPosition - headerOffset;
      document?.getElementById('root')?.scrollBy({ top: offsetPosition, behavior: 'smooth' });
    }
  };

  return (
    <Header className={`header-block ${className || ''}`}>
      <Row>
        <Col span={5}>
          <Link to={'/'}>
            <img className="logo" src={ecLogo} alt="logo" />
          </Link>
        </Col>
        <Col span={10}>
          {!token && pathname === '/' && (
            <Row justify="center">
              <a onClick={(e) => onPress(e)} href="#definition">
                <div className="menu-item">Experportとは</div>
              </a>
              <a onClick={(e) => onPress(e)} href="#features">
                <div className="menu-item">機能概要</div>
              </a>
              <a onClick={(e) => onPress(e)} href="#reason">
                <div className="menu-item">選ばれる理由</div>
              </a>
            </Row>
          )}
        </Col>
        <Col offset={1} span={8}>
          <>
            {!token && renderHeaderUser()}
            {/* {token && userName && renderAuthHeaderUser()} */}
          </>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderComponent;
