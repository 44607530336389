import { AxiosResponse } from 'axios';
import { API_URL } from 'utils/config';
import apiInstance from '../utils/api';

export default class UserService extends apiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  login = (payload: any): Promise<AxiosResponse> => {
    return this.post(`${API_URL}/v1/user/login`, payload);
  };

  getCurrentUser = (payload: any): Promise<AxiosResponse> => {
    return this.get(`${API_URL}/v1/user/profile`, payload);
  };

  preCheckEmail = (payload: string): Promise<AxiosResponse> => {
    return this.post(`${API_URL}/v1/public/user/pre-sign-up-Check`, payload);
  };

  resetPassword = (payload: string): Promise<AxiosResponse> => {
    return this.post(`${API_URL}/v1/public/user/send-mail-forgot-password `, payload);
  };

  checkExpiredLink = (payload: string): Promise<AxiosResponse> => {
    return this.post(`${API_URL}/v1/public/user/check-expried-link-forgot-password `, payload);
  };

  changePassword = (payload: string): Promise<AxiosResponse> => {
    return this.post(`${API_URL}/v1/public/user/forgot-password`, payload);
  };
}
