import { Typography } from 'antd';
import './style.less';
const { Title } = Typography;

interface PropsGreenBox {
  title: string;
  type?: string;
  className?: string;
  children: React.ReactNode;
}

const GreenBox: React.FC<PropsGreenBox> = ({ title, type, className, children }) => (
  <div className={`greenbox-wrapper ${className || ''}`}>
    <div className="greenbox-inner">
      <Title level={4}>{title}</Title>
      {children}
    </div>
  </div>
);

export default GreenBox;
