import _get from 'lodash/get';

export function timeout(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export type ListResponseType = {
  list: Record<string, unknown>[];
  total: number;
};

export type GetListParamsType = {
  page: number;
  limit: number;
  orderBy: string;
  order: string;
  keyword: string;
};

export const getList = async (
  data: Record<string, unknown>[],
  params: GetListParamsType,
  filters: string[],
  sorter: Record<string, string>,
  searchBy?: string[],
): Promise<ListResponseType> => {
  const { page, limit, orderBy, order, keyword } = params;
  const start = (page - 1) * limit;
  const end = page * limit;

  filters.forEach((filter) => {
    if (params[filter]) {
      const filteredValue = params[filter].split(',');
      data = data.filter((value) => filteredValue.includes(value[filter]));
    }
  });

  if (orderBy) {
    data.sort((a: Record<string, string>, b: Record<string, string>) => {
      if (sorter[orderBy] === 'string') {
        if (order === 'ascend') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        }
        if (order === 'descend') {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }

      return 1;
    });
  }

  if (keyword) {
    data = data.filter((value) =>
      searchBy?.map((key) => (value[key] as string).includes(keyword)).some((element) => element),
    );
  }

  await timeout(1000);

  return {
    list: data.slice(start, end),
    total: data.length,
  };
};

export const getDetail = async (
  data: Record<string, unknown>[],
  id: string,
): Promise<Record<string, unknown>> => {
  await timeout(1000);
  return data.find((element) => element.id === id) || {};
};

export type GetCSVStringParamsType = {
  title: string;
  dataIndex?: string;
  render?: (value, record) => string;
}[];

export const getCSVString = async (
  data: Record<string, unknown>[],
  columns: GetCSVStringParamsType,
): Promise<string> => {
  const csvRows: string[] = [];

  csvRows.push(columns.map((column) => column.title).join(';'));

  data.forEach((record) => {
    csvRows.push(
      columns
        .map((column) => {
          const { render, dataIndex } = column;
          const value = _get(record, dataIndex || '');

          return render ? render(value, record) : value;
        })
        .join(';'),
    );
  });

  await timeout(1000);

  return csvRows.join('\r\n');
};
