import { lazy, FunctionComponent } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import GpsFixedRoundedIcon from '@mui/icons-material/GpsFixedRounded';

import Plugins from 'plugins';
import PreCheckEmailSignUpPage from 'containers/SignUp/PreCheckEmailSignUpPage';
import VerifyEmailSuccessPage from 'containers/SignUp/VerifyEmailSuccessPage';
import HomePage from 'containers/HomePage';
import LoginPage from 'containers/LoginPage';
import RegisterPage from 'containers/RegisterPage';
import ResetPasswordRequestPage from 'containers/SupportPage/ResetPasswordRequestPage';
import ResetPassWordNotificationPage from 'containers/SupportPage/ResetPasswordNotificationPage';
import ResetPasswordPage from 'containers/SupportPage/ResetPasswordPage';
import ConfirmChangeEmail from 'containers/ConfirmChangeEmailPage';
import DashboardPage from 'containers/Dashboard';
import NotFoundPage from 'containers/NotFoundPage';
import SkillsPage from 'containers/SkillsPage';
import IntroductionPage from 'containers/IntroductionPage';
import AchievementPage from 'containers/AchievementPage';
import SettingPage from 'containers/SettingPage';
import PrivateInformationPage from 'containers/PrivateInformationPage';
import LinkingConfirmationPage from 'containers/SupportPage/LinkingConfirmationPage';
import LinkingConfirmErrorPage from 'containers/SupportPage/LinkingConfirmErrorPage';
import LinkingEngineerRegisterPage from 'containers/SupportPage/LinkingEngineerRegister';
import RecommendationManagementPage from 'containers/RecommendationManagementPage';
// import Extensions from 'extensions';

// PLOP: Add pages import below -- DO NOT ERASE

const NotFoundRedirect = (): JSX.Element => <Redirect to="/404-not-found" />;

// const RedirectToHome = (): JSX.Element => <Redirect to="/home" />;

const originalRoutes = [
  { path: '/404-not-found', component: NotFoundPage, exact: true },
  {
    path: '/dashboard',
    component: DashboardPage,
    requireLogin: true,
    exact: true,
    // For menu layout
    menu: true,
    name: 'menuBar:dashboard',
    icon: <span className="menu-icon icon-home" />,
  },
  {
    path: '/self-introduction',
    component: IntroductionPage,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBar:introduction',
    icon: <span className="menu-icon icon-introduction" />,
  },
  {
    path: '/skills',
    type: 'group',
    component: SkillsPage,
    requireLogin: true,
    menu: true,
    name: 'menuBar:skills',
    icon: <span className="menu-icon icon-skills" />,
    routes: [
      {
        path: '/skills/role',
        requireLogin: true,
        exact: true,
        menu: true,
        name: 'menuBar:role',
        icon: (
          <span role="img" aria-label="setting" className="anticon anticon-setting">
            <StarOutlineRoundedIcon />
          </span>
        ),
      },
      {
        path: '/skills/domain-knowledge',
        requireLogin: true,
        exact: true,
        menu: true,
        name: 'menuBar:knowledge',
        icon: (
          <span role="img" aria-label="setting" className="anticon anticon-setting">
            <StarOutlineRoundedIcon />
          </span>
        ),
      },
      {
        path: '/skills/technical-skills',
        requireLogin: true,
        exact: true,
        menu: true,
        name: 'menuBar:technicalSkill',
        icon: (
          <span role="img" aria-label="setting" className="anticon anticon-setting">
            <StarOutlineRoundedIcon />
          </span>
        ),
      },
      {
        path: '/skills/languages',
        requireLogin: true,
        exact: true,
        menu: true,
        name: 'menuBar:languages',
        icon: (
          <span role="img" aria-label="setting" className="anticon anticon-setting">
            <StarOutlineRoundedIcon />
          </span>
        ),
      },
      {
        path: '/skills/work-experience',
        requireLogin: true,
        exact: true,
        menu: true,
        name: 'menuBar:experience',
        icon: (
          <span role="img" aria-label="setting" className="anticon anticon-setting">
            <StarOutlineRoundedIcon />
          </span>
        ),
      },
    ],
  },
  {
    path: '/work-environment',
    component: PrivateInformationPage,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBar:workEnvironment',
    icon: <span className="menu-icon icon-work-environment" />,
  },
  {
    path: '/recommendation',
    component: RecommendationManagementPage,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBar:recommendation',
    icon: <span className="menu-icon icon-recommendation" />,
  },
  {
    path: '/setting',
    component: SettingPage,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBar:settings',
    icon: <span className="menu-icon icon-setting" />,
  },
  // PLOP: Add routes import below -- DO NOT ERASE
  {
    path: '/pre-check-email-sign-up',
    component: PreCheckEmailSignUpPage,
    requireLogin: false,
    exact: true,
  },
  {
    path: '/verify-email-success',
    component: VerifyEmailSuccessPage,
    requireLogin: false,
    exact: true,
  },
  {
    path: '/home',
    component: HomePage,
    requireLogin: false,
    exact: true,
  },
  {
    path: '/login',
    component: LoginPage,
    requireLogin: false,
    exact: true,
  },
  {
    path: '/register',
    component: RegisterPage,
    requireLogin: false,
    exact: true,
  },
  {
    path: '/reset-password',
    component: ResetPasswordRequestPage,
    requireLogin: false,
    exact: true,
  },
  {
    path: '/sent-reset-password-request',
    component: ResetPassWordNotificationPage,
    requireLogin: false,
    exact: true,
  },
  {
    path: '/input-new-password',
    component: ResetPasswordPage,
    requireLogin: false,
    exact: false,
  },
  {
    path: '/confirm-change-email',
    component: ConfirmChangeEmail,
    requireLogin: false,
    exact: true,
  },
  {
    path: '/engineer-linking-confirmation',
    component: LinkingConfirmationPage,
    requireLogin: false,
    exact: true,
  },
  {
    path: '/confirm-linking-url-expired',
    component: LinkingConfirmErrorPage,
    requireLogin: false,
    exact: true,
  },
  {
    path: '/engineer-register',
    component: LinkingEngineerRegisterPage,
    requireLogin: false,
    exact: true,
  },
];

// let extendedRoutes = [];
// for (const pluginRouteIndex in Plugins.routes) {
//   if (!_isEmpty(Extensions.routes[pluginRouteIndex])) {
//     extendedRoutes = extendedRoutes.concat(Extensions.routes[pluginRouteIndex]);
//   } else {
//     extendedRoutes = extendedRoutes.concat(Plugins.routes[pluginRouteIndex]);
//   }
// }
let extendedRoutes = [];
for (const pluginRouteIndex in Plugins.routes) {
  extendedRoutes = extendedRoutes.concat(Plugins.routes[pluginRouteIndex]);
}

interface routeTypes {
  path: string;
  component: FunctionComponent<RouteComponentProps>;
  requireLogin?: boolean | undefined;
  exact?: boolean;
  menu?: boolean | undefined;
  name?: string | undefined;
  icon?: React.ReactNode;
  routes?:
    | Array<{
        path: string;
        name: string;
        icon: React.ReactNode;
      }>
    | undefined;
}
const indexRoutes: Array<routeTypes> = [
  ...originalRoutes,
  ...extendedRoutes,
  {
    path: '/',
    component: HomePage,
    requireLogin: false,
    exact: true,
  },
  { path: '*', component: NotFoundRedirect },
];
export default indexRoutes;
