/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Form, Input, Space, Typography } from 'antd';
import ReactMaskedInput from 'react-input-mask';
import { InfoCircleOutlined } from '@ant-design/icons';
import { InputProps } from 'antd/lib/input';
import _get from 'lodash/get';
import { useController, Path, Control, FieldValues } from 'react-hook-form';
import { useState } from 'react';

import './style.less';

interface MaskedInputProps<TFormValues extends FieldValues> extends InputProps {
  label?: string;
  className?: string;
  inputType?: 'password' | 'textarea' | 'search' | undefined;
  required?: boolean;
  placeholder?: string;
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  mask: string;
  noLabel?: boolean;
  colLabel?: number;
  colWrapper?: number;
  isShowMaxLength?: boolean;
  formLayout?: 'horizontal' | 'vertical';
}

const MaskedInput = <TFormValues extends FieldValues>({
  label,
  noLabel,
  maxLength,
  className,
  placeholder,
  name,
  control,
  mask,
  required,
  colLabel,
  colWrapper,
  formLayout = 'horizontal',
  isShowMaxLength = true,
  ...rest
}: MaskedInputProps<TFormValues>) => {
  const {
    field: { ref, onChange, value, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const [isFocus, setIsFocus] = useState(false);

  const formItemLayout = colLabel
    ? {
        labelCol: { span: colLabel },
        wrapperCol: { span: colWrapper || 24 - colLabel },
      }
    : {};

  return (
    <Form.Item
      className={`${formLayout} mask-input-container ${className}`}
      {...formItemLayout}
      label={
        <Space>
          <Typography.Text className={isFocus ? 'on-focus' : ''}>{label}</Typography.Text>
          {required ? (
            <Typography.Text className="required-mark">必須</Typography.Text>
          ) : (
            <Typography.Text className="not-required-mark">任意</Typography.Text>
          )}
        </Space>
      }
      colon={false}
      validateStatus={error ? 'error' : 'success'}
      help={
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <div className="error-text">
            {error && (
              <>
                <InfoCircleOutlined />
                {error.message}
              </>
            )}
          </div>
          {maxLength && isShowMaxLength && (
            <div>
              {_get(value, 'length', 0)}/{maxLength}
            </div>
          )}
        </Space>
      }
    >
      <ReactMaskedInput name={name} mask={mask} value={value} onChange={onChange} onBlur={onBlur}>
        {(inputProps) => (
          <Input
            {...inputProps}
            placeholder={placeholder}
            maxLength={maxLength}
            ref={ref}
            onFocusCapture={() => setIsFocus(true)}
            onBlurCapture={() => setIsFocus(false)}
            {...rest}
          />
        )}
      </ReactMaskedInput>
    </Form.Item>
  );
};

export default MaskedInput;
