import { PageContainer } from '@ant-design/pro-layout';
import { Space, Spin, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { useAppDispatch } from 'store';
import { ICertInitValue, IListTechnicalByGroup, IListTechnical } from 'models/skills/interface';
import TechnicalList from 'components/skills/TechnicalList';
import TechnicalForm from 'components/skills/TechnicalForm';
import TechnicalCertificationsForm from 'components/skills/TechnicalCertificationForm';
import TechnicalCertificationsList from 'components/skills/TechnicalCertificationList';
import { useFetchUser, useMutate } from 'hooks';
import useFetchList from 'hooks/useList';
import skillsQuery from 'models/skills/query';
import { setConfirmModal } from 'providers/GeneralProvider/slice';
import Helper from 'utils/helpers';
import './styles.less';
const { Title } = Typography;

const TechnicalSkillPage = (): JSX.Element => {
  const { t } = useTranslation();
  const header = {
    title: t('title:technicalSkillPage'),
    breadcrumb: {
      routes: [
        {
          path: '',
          breadcrumbName: `${t('menuBar:skills')} / ${t('title:technicalSkillPage')}`,
        },
      ],
    },
  };

  const dispatch = useAppDispatch();

  // state
  const [initialValueForm, setInitialValueForm] = useState({});
  const [userCertification, setUserCertification] = useState<any>({});

  const { data } = useFetchUser({ enabled: true });
  const {
    list: dataList,
    isLoading: fetchData,
    refetch: refetchList,
  } = useFetchList<IListTechnicalByGroup>({
    ...skillsQuery.technicalList,
    customParams: {
      ...skillsQuery.technicalList.customParams,
      userId: _get(data, '_id'),
    },
  });
  const { mutateAsync: deleteItem, isLoading: isLoadingDelete } = useMutate<{
    categoryTechnical: string;
  }>(skillsQuery.deleteTechnical);

  const resetInitialValue = () => {
    setInitialValueForm({});
  };

  const handLeDeleteItem = (id: string) => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          className: 'non-title delete-confirm',
          content: t('description:deleteConfirm'),
          okText: t('button:deleteButton'),
          cancelText: t('button:cancelButton'),
          onOk: () => {
            deleteItem(
              { categoryTechnical: id },
              {
                onSuccess: () => {
                  Helper.toasts('', t('skills:deleteSuccess'), 'success');
                  resetInitialValue();
                  refetchList();
                },
                onError: (data) => {
                  Helper.toasts('', _get(data, 'message'), 'error');
                },
              },
            );
          },
        },
      }),
    );
  };
  // Certification actions
  const {
    list: certificationList,
    isLoading: fetchingCert,
    refetch: refetchCertificationList,
  } = useFetchList<ICertInitValue>(skillsQuery.technicalCertificationList);
  const { mutateAsync: deleteCertification, isLoading: isLoadingDelCert } = useMutate<{
    certificationId: string;
  }>(skillsQuery.deleteCertification);

  // Edit and delete certification
  const handleEditCertification = (item) => {
    setUserCertification(item);
    handleScrollTechnicalCertificate();
  };

  const handleDeleteCertification = (id) => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          className: 'non-title delete-confirm',
          content: t('description:deleteConfirm'),
          okText: t('button:deleteButton'),
          cancelText: t('button:cancelButton'),
          onOk: () => {
            deleteCertification(
              { certificationId: id },
              {
                onSuccess: () => {
                  refetchCertificationList();
                  resetInitialValueCert();
                  Helper.toasts('', t('skills:deleteSuccess'), 'success');
                },
                onError: (data) => {
                  Helper.toasts('', _get(data, 'message'), 'error');
                },
              },
            );
          },
        },
      }),
    );
  };

  // scroll to top form section when edit
  const handleScrollTechnicalForm = () => {
    const element = document.querySelector('.technical-scroll');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollTechnicalCertificate = () => {
    const element = document.querySelector('.technical-cert-scroll');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleEditItem = (item: IListTechnical) => {
    setInitialValueForm(item);
    handleScrollTechnicalForm();
  };
  const resetInitialValueCert = () => {
    setUserCertification({});
  };

  return (
    <PageContainer header={header}>
      <Space direction="vertical" className="skills-page-wrapper technical-wrapper">
        <div className="technical-scroll"></div>
        <Typography style={{ padding: '0 15px 0 28px', fontWeight: 500 }}>
          使用可能な技術とそのレベル・経験年数を登録しましょう。
        </Typography>
        <div className="green-opacity-bg inner-content block-01">
          <Title level={4}>{t('title:technicalTitle')}</Title>
          <TechnicalForm
            initialValue={initialValueForm}
            resetInitialValue={resetInitialValue}
            refreshDataList={refetchList}
          />
          <Spin spinning={fetchData || isLoadingDelete}>
            {dataList?.length > 0 &&
              dataList.map(({ skillCategory, technicals }) => (
                <div className="technical-group" key={skillCategory._id}>
                  <Title level={3}>{skillCategory.name}</Title>
                  {technicals.length > 0 && (
                    <TechnicalList
                      data={technicals}
                      handleEdit={handleEditItem}
                      handleDelete={handLeDeleteItem}
                    />
                  )}
                </div>
              ))}
          </Spin>
        </div>
        <div className="technical-cert-scroll"></div>
        <div className="mt-80 green-opacity-bg inner-content block-01">
          <Title level={4}>{t('title:qualificationTitle')}</Title>
          <TechnicalCertificationsForm
            resetInitialValue={resetInitialValueCert}
            refreshDataList={refetchCertificationList}
            initialValue={userCertification}
          />
          {certificationList?.length > 0 && (
            <TechnicalCertificationsList
              data={certificationList}
              initLoading={fetchingCert || isLoadingDelCert}
              handleEdit={handleEditCertification}
              handleDelete={handleDeleteCertification}
            />
          )}
        </div>
      </Space>
    </PageContainer>
  );
};

export default TechnicalSkillPage;
