import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, Space, Typography } from 'antd';
import { InputProps } from 'antd/lib/input';
import _get from 'lodash/get';
import type { Control, FieldValues, Path } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { useState } from 'react';

import './style.less';
import '../style.less';
import HelperText from '../HelperTextTooltip';

interface FormTextInputProps<TFormValues extends FieldValues> extends InputProps {
  label?: string;
  className?: string;
  required?: boolean;
  placeholder?: string;
  control?: Control<TFormValues>;
  name: Path<TFormValues>;
  suffixOutside?: boolean;
  suffix?: string;
  isShowMaxLength?: boolean;
  colLabel?: number;
  colWrapper?: number;
  formLayout?: 'horizontal' | 'vertical';
  helperText?: string;
}

const TextArea = <TFormValues extends FieldValues>({
  label,
  maxLength,
  required,
  control,
  name,
  suffixOutside = false,
  suffix,
  colLabel,
  colWrapper,
  isShowMaxLength,
  formLayout = 'horizontal',
  helperText,
  ...props
}: FormTextInputProps<TFormValues>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const [isFocus, setIsFocus] = useState(false);

  const formItemLayout = colLabel
    ? {
        labelCol: { span: colLabel },
        wrapperCol: { span: colWrapper || 24 - colLabel },
      }
    : {};

  return (
    <Form.Item
      className={formLayout}
      {...formItemLayout}
      label={
        label ? (
          <Space>
            <Space size={6} style={{ padding: 0 }}>
              {helperText && (
                <>
                  <HelperText text={helperText} />
                </>
              )}
              <Typography.Text className={isFocus ? 'on-focus' : ''}>{label}</Typography.Text>
            </Space>
            {required ? (
              <Typography.Text className="required-mark">必須</Typography.Text>
            ) : (
              <Typography.Text className="not-required-mark">任意</Typography.Text>
            )}
          </Space>
        ) : null
      }
      colon={false}
      validateStatus={error ? 'error' : 'success'}
      help={
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <div>
            {error && (
              <div className="error-text">
                <InfoCircleOutlined />
                {error.message}
              </div>
            )}
          </div>
          {maxLength && isShowMaxLength && (
            <div>
              {_get(field, 'value.length', 0)}/{maxLength}
            </div>
          )}
        </Space>
      }
    >
      {suffixOutside ? (
        <>
          <Input
            {...props}
            {...field}
            allowClear
            maxLength={maxLength}
            suffix={null}
            onFocus={() => setIsFocus(true)}
            onBlurCapture={() => setIsFocus(false)}
          />
          <span className="ant-input-suffix">{suffix}</span>
        </>
      ) : (
        <Input
          {...props}
          {...field}
          allowClear
          maxLength={maxLength}
          suffix={suffix ? <span className="ant-input-suffix">{suffix}</span> : null}
          onFocus={() => setIsFocus(true)}
          onBlurCapture={() => setIsFocus(false)}
        />
      )}
    </Form.Item>
  );
};

export default TextArea;
