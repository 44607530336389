/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
import { PlusOutlined } from '@ant-design/icons';
import SelectField from 'components/Form/SelectNew';
import TextInput from 'components/Form/TextInputNew';
// hooks
import useFetchList from 'hooks/useList';
import resourceQuery from 'models/resource/query';
import '../style.less';
import { ITechnicalCategory, ITechnicalSkill, ILevelItem } from 'models/resource/interface';
import FormSchema from './validate';

type FormValues = InferType<typeof FormSchema>;

const TechnicalForm: React.FC<{
  refreshForm: boolean;
  addItemToDataList: (itemValues: any) => void;
}> = ({ addItemToDataList, refreshForm = false }) => {
  // state
  const [technicalList, setTechnicalList] = useState<any>([
    {
      _id: 'other',
      nameJa: 'その他',
      nameEn: 'Other',
    },
  ]);
  const { control, handleSubmit, reset, setValue, clearErrors } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { t } = useTranslation();

  // get all category list - Level list
  const { list: listCategory, isFetching: fetchingCategoryData } = useFetchList<ITechnicalCategory>(
    { ...resourceQuery.technicalCategory },
  );

  const { list: listLevel, isFetching: fetchingLevelData } = useFetchList<ILevelItem>(
    resourceQuery.level,
  );

  const categoryValue = useWatch({ name: 'usedTechnologyCategory', control: control });
  const skillNameValue = useWatch({ name: 'usedTechnologyName', control: control });
  const skillText = useWatch({ name: 'otherUsedTechnologyName', control: control });
  const level = useWatch({ name: 'usedTechnologyLevel', control: control });

  // get Skill list from category
  const { list: listSkill, refetch: refetchListSkill } = useFetchList<ITechnicalSkill>({
    ...resourceQuery.technicalSkill,
    enabled: !!categoryValue,
    apiUrl: `/v1/experience/technical/skill/${categoryValue}`,
  });

  useEffect(() => {
    if (!_isEmpty(categoryValue)) {
      refetchListSkill();
    }
  }, [categoryValue]);

  useEffect(() => {
    if (refreshForm) {
      reset();
    }
  }, [refreshForm]);

  useEffect(() => {
    if (skillNameValue !== 'other') {
      clearErrors('usedTechnologyName');
      setValue('otherUsedTechnologyName', skillNameValue);
    }
  }, [skillNameValue]);

  useEffect(() => {
    if (listSkill.length > 0) {
      const skillMap: Record<string, any> = {};
      let lastCategory = '';
      listSkill.map((skill) => {
        const categoryName = _get(skill, 'skillTechnical.name');
        lastCategory = categoryName;
        if (!skillMap[categoryName]) skillMap[categoryName] = [];
        skillMap[categoryName].push({ value: skill._id, label: skill.nameJa });
      });
      if (Object.keys(skillMap).length > 1) {
        skillMap[lastCategory].push({ label: 'その他', value: 'other' });
        setTechnicalList(
          Object.keys(skillMap).map((key) => ({ label: key, options: skillMap[key] })),
        );
      } else {
        setTechnicalList([
          ...listSkill,
          {
            _id: 'other',
            nameJa: 'その他',
            nameEn: 'Other',
          },
        ]);
      }
    }
  }, [listSkill]);

  const handleFormSubmit = () => {
    const valuesSubmit = {
      usedTechnologyCategory: _find(listCategory, function (o) {
        return o._id === categoryValue;
      }),
      usedTechnologyName: _find(
        [
          ...listSkill,
          {
            _id: 'other',
            nameJa: 'その他',
            nameEn: 'Other',
          },
        ],
        function (o) {
          return o._id === skillNameValue;
        },
      ),
      otherUsedTechnologyName: skillText,
      usedTechnologyLevel: _find(listLevel, function (o) {
        return o._id === level;
      }),
    };
    addItemToDataList(valuesSubmit);
    reset();
  };

  return (
    <form className="skills-form-container technologies-form-container">
      <Spin spinning={fetchingCategoryData || fetchingLevelData}>
        <div className="row-block">
          <SelectField
            name="usedTechnologyCategory"
            label={t('skills:skillCategoryField')}
            control={control}
            formLayout="vertical"
            placeholder={t('placeholder:fieldSelect')}
            data={listCategory}
            colLabel={12}
            colWrapper={12}
            onSelect={(value) => {
              if (!_isEqual(value, categoryValue)) {
                setValue('usedTechnologyLevel', '');
                clearErrors('usedTechnologyLevel');
                setValue('usedTechnologyName', '');
                clearErrors('usedTechnologyName');
                clearErrors('usedTechnologyCategory');
              }
            }}
            onClear={() => {
              setValue('usedTechnologyCategory', '', { shouldValidate: true });
              setValue('usedTechnologyLevel', '');
              clearErrors('usedTechnologyLevel');
              setValue('usedTechnologyName', '');
              clearErrors('usedTechnologyName');
            }}
          />
        </div>
        <div
          className={`row-block ${
            categoryValue && skillNameValue && skillNameValue === 'other' ? 'show-skill-text' : ''
          }`}
        >
          <SelectField
            name="usedTechnologyName"
            label={t('skills:skillName')}
            control={control}
            formLayout="vertical"
            placeholder={t('placeholder:fieldSelect')}
            data={technicalList}
            colLabel={12}
            colWrapper={12}
            onSelect={(value) => {
              if (!_isEqual(value, skillNameValue)) {
                setValue('usedTechnologyLevel', '');
                clearErrors('usedTechnologyName');
                clearErrors('otherUsedTechnologyName');
                setValue('otherUsedTechnologyName', '');
              }
            }}
            onClear={() => {
              setValue('usedTechnologyName', '', { shouldValidate: true });
              setValue('otherUsedTechnologyName', '', { shouldValidate: true });
              setValue('usedTechnologyLevel', '');
              clearErrors('usedTechnologyLevel');
            }}
            disabled={!categoryValue}
          />
          {categoryValue && skillNameValue && skillNameValue === 'other' && (
            <TextInput
              name="otherUsedTechnologyName"
              control={control}
              placeholder={t('placeholder:fieldDetail')}
              formLayout="vertical"
              colLabel={12}
              colWrapper={12}
              disabled={!categoryValue || !skillNameValue}
              maxLength={100}
              isShowMaxLength={false}
            />
          )}
        </div>
        <div className="row-block">
          <SelectField
            name="usedTechnologyLevel"
            label={t('skills:skillLevel')}
            formLayout="vertical"
            control={control}
            placeholder={t('placeholder:fieldSelect')}
            data={listLevel}
            onClear={() => setValue('usedTechnologyLevel', '', { shouldValidate: true })}
            disabled={!categoryValue || !skillNameValue || !skillText}
            colLabel={12}
            colWrapper={12}
          />
        </div>
        <div className="submit-btn-wrapper">
          <Button
            htmlType="button"
            onClick={handleSubmit(handleFormSubmit)}
            className="sub-second-color"
            type="primary"
            disabled={
              _isEmpty(categoryValue) ||
              _isEmpty(skillNameValue) ||
              _isEmpty(skillText) ||
              _isEmpty(level)
            }
          >
            <PlusOutlined />
            追加する
          </Button>
        </div>
      </Spin>
    </form>
  );
};

export default TechnicalForm;
